<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ร้านค้า
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack('manageshop')"> จัดการร้านค้า </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ navigete }}
      </v-card-title>
    </v-card>

    <!-- เพิ่มกลุ่มจังหวัด -->
    <v-dialog
      v-model="opp.dialog"
      scrollable
      width="515"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3" class="mr-3">mdi-group</v-icon>
          <!-- <span v-if="!opp.isUpdate">เพิ่มกลุ่มจังหวัด</span>
          <span v-else>แก้ไขกลุ่มจังหวัด</span> -->
          กลุ่มจังหวัด
          <v-spacer />
          <v-btn icon @click="opp.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="oppdialog" lazy-validation>
            <v-row>
              <v-col cols="3"> ชื่อกลุ่มจังหวัด: </v-col>
              <v-col cols="9">
                <v-text-field
                  :value="provinceGroup.description"
                  @change="(value) => (provinceGroup.description = Trim_value(value))"
                  ref="provinceGroupdescription"
                  :rules="provinceGroup.rulesDescription"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pt-2"> เลือกจังหวัด: {{ total }} </v-col>
              <v-col cols="9" class="pt-2">
                <v-row class="select-province ma-0">
                  <v-col
                    cols="12"
                    id="scroll-target"
                    class="overflow-y-auto pa-2 height-515"
                  >
                    <v-checkbox
                      v-for="i in province"
                      v-model="selectProvince"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                      :disabled="i.disabled"
                      color="info"
                      class="mr-3"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" class="px-2" @click="UpdateProvinceGroup()">
            <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
            <span class="fn-14"><b> บันทึก </b></span>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-form ref="formAddShop" lazy-validation>
      <!-- ร้านค้า -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          <span v-if="data.shop.id > 0">แก้ไขร้านค้า</span>
          <span v-else>ข้อมูลร้านค้า</span>
        </v-card-title>
        <v-card-text>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> ชื่อร้านค้า : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shop.name"
                    @change="(value) => (data.shop.name = Trim_value(value))"
                    ref="datashopname"
                    @keyup.enter="$refs.datashopAddressphone.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> เบอร์โทร : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddress.phone"
                    @change="(value) => (data.shopAddress.phone = Trim_value(value))"
                    ref="datashopAddressphone"
                    @keyup.enter="$refs.datashopAddressaddress.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    maxlength="10"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> ที่อยู่ : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddress.address"
                    @change="(value) => (data.shopAddress.address = Trim_value(value))"
                    ref="datashopAddressaddress"
                    @keyup.enter="$refs.datashopemail.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> อีเมล : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shop.email"
                    @change="(value) => (data.shop.email = Trim_value(value))"
                    ref="datashopemail"
                    @keyup.enter="$refs.datashopAddresspostcodeMapping.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> รหัสไปรษณีย์ : </v-col>
                <v-col cols="12" md="8">
                  <v-autocomplete
                    v-model="data.shopAddress.postcodeMapping"
                    label="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    outlined
                    ref="datashopAddresspostcodeMapping"
                    @keyup.enter="$refs.datastaffShopname.focus()"
                    :rules="[(v) => !!v || 'กรุณาระบุ']"
                    autocomplete="off"
                    required
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- ผู้ใช้งานเริ่มต้น -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title> สร้างผู้ใช้งานเริ่มต้น </v-card-title>
        <v-card-text>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> ชื่อ-สกุล : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.staffShop.name"
                    @change="(value) => (data.staffShop.name = Trim_value(value))"
                    ref="datastaffShopname"
                    @keyup.enter="$refs.datastaffShopphone.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> เบอร์โทร : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.staffShop.phone"
                    @change="(value) => (data.staffShop.phone = Trim_value(value))"
                    ref="datastaffShopphone"
                    @keyup.enter="$refs.datastaffShopusername.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    maxlength="10"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> Username : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-if="data.shop.id > 0"
                    :value="data.staffShop.username"
                    @change="(value) => (data.staffShop.username = Trim_value(value))"
                    ref="datastaffShopusername"
                    @keyup.enter="$refs.datastaffShoppassword.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :value="data.staffShop.username"
                    @change="(value) => (data.staffShop.username = Trim_value(value))"
                    ref="datastaffShopusername"
                    @keyup.enter="$refs.datastaffShoppassword.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> Password : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.staffShop.password"
                    @change="(value) => (data.staffShop.password = Trim_value(value))"
                    ref="datastaffShoppassword"
                    @keyup.enter="$refs.datashopAddressInvoicename.focus()"
                    :rules="[(v) => Trim_rules(v) || 'กรุณาระบุ']"
                    autocomplete="off"
                    outlined
                    :append-icon="data.staffShop.showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="data.staffShop.showpassword ? 'text' : 'password'"
                    @click:append="
                      data.staffShop.showpassword = !data.staffShop.showpassword
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- ที่อยู่ในการออกใบกำกับภาษี -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title> ที่อยู่ในการออกใบกำกับภาษี </v-card-title>
        <v-card-text>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> ชื่อบุคคล/นิติบุคคล : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddressInvoice.name"
                    @change="
                      (value) => (data.shopAddressInvoice.name = Trim_value(value))
                    "
                    ref="datashopAddressInvoicename"
                    @keyup.enter="$refs.datashopAddressInvoicephone.focus()"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> เบอร์โทร : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddressInvoice.phone"
                    @change="
                      (value) => (data.shopAddressInvoice.phone = Trim_value(value))
                    "
                    ref="datashopAddressInvoicephone"
                    @keyup.enter="$refs.datashopAddressInvoiceaddress.focus()"
                    autocomplete="off"
                    maxlength="10"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> ที่อยู่ : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddressInvoice.address"
                    @change="
                      (value) => (data.shopAddressInvoice.address = Trim_value(value))
                    "
                    ref="datashopAddressInvoiceaddress"
                    @keyup.enter="$refs.datashopAddressInvoiceidCardNumber.focus()"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> เลขประจำตัวผู้เสียภาษี : </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :value="data.shopAddressInvoice.idCardNumber"
                    @change="
                      (value) =>
                        (data.shopAddressInvoice.idCardNumber = Trim_value(value))
                    "
                    ref="datashopAddressInvoiceidCardNumber"
                    @keyup.enter="$refs.datashopAddressInvoicepostcodeMapping.focus()"
                    autocomplete="off"
                    maxlength="13"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col cols="12" md="6">
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3"> รหัสไปรษณีย์ : </v-col>
                <v-col cols="12" md="8">
                  <v-autocomplete
                    v-if="data.shop.minimumCredit == 1"
                    v-model="data.shopAddressInvoice.postcodeMapping"
                    label="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    ref="datashopAddressInvoicepostcodeMapping"
                    @keyup.enter="$refs.datashopbalanceLimit.focus()"
                    autocomplete="off"
                    outlined
                    required
                    return-object
                  ></v-autocomplete>
                  <v-autocomplete
                    v-else
                    v-model="data.shopAddressInvoice.postcodeMapping"
                    label="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                    :items="postcode_mapping"
                    :item-text="
                      (item) =>
                        item.Postcode +
                        ' ' +
                        item.Province +
                        ' ' +
                        item.District +
                        ' ' +
                        item.SubDistrict
                    "
                    ref="datashopAddressInvoicepostcodeMapping"
                    autocomplete="off"
                    outlined
                    required
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- ค่าบริการรายเดือน -->
      <v-dialog
        v-model="data.shopMonthlyFee.dialog"
        scrollable
        max-width="50%"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card elevation="1" class="ma-0">
          <v-card-title>
            <span v-if="!data.shopMonthlyFee.pUpdate">เพิ่มค่าบริการ</span>
            <span v-else>แก้ไขค่าบริการ</span>
            <v-spacer />
            <v-btn icon @click="data.shopMonthlyFee.dialog = false"
              ><v-icon color="danger">mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider />
          <v-card-text class="overflow-y-auto">
            <v-form ref="dialogShopMonthlyFeeForm" lazy-validation>
              <v-row row wrap class="py-1">
                <v-col cols="12" md="3">
                  <h4>ค่าบริการ :</h4>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    :value="data.shopMonthlyFee.serviceCharge"
                    @change="
                      (value) => (data.shopMonthlyFee.serviceCharge = Trim_value(value))
                    "
                    :rules="data.shopMonthlyFee.rulesServiceCharge"
                    autocomplete="off"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <h4>ยอด :</h4>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    class="textbox-inline-block"
                    :value="data.shopMonthlyFee.price"
                    @change="(value) => (data.shopMonthlyFee.price = Trim_value(value))"
                    :rules="data.shopMonthlyFee.rulesPrice"
                    autocomplete="off"
                    outlined
                    style="width: 200px"
                    @keypress="isNumberWNoDot($event)"
                  ></v-text-field>
                  <span class="pl-2">บาท/เดือน</span>
                </v-col>
                <v-col cols="12" md="3">
                  <h4>วันที่เริ่ม :</h4>
                </v-col>
                <v-col cols="12" md="9">
                  <v-menu
                    ref="menuStartDate"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart"
                        outlined
                        readonly
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        @blur="startDate = parseDate(dateStart)"
                        v-bind="attrs"
                        v-on="on"
                        :rules="data.shopMonthlyFee.rulesStartDate"
                        clearable
                        @click:clear="dateStart = null"
                        style="width: 200px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="menuStartDate = false"
                      @change="
                        (dateStart = formatDate(startDate)),
                          CheckDateTime(startDate, endDate)
                      "
                      no-title
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <h4>วันที่สิ้นสุด :</h4>
                </v-col>
                <v-col cols="12" md="9">
                  <v-radio-group row v-model="data.shopMonthlyFee.selectEndDate">
                    <v-radio
                      v-for="i in data.shopMonthlyFee.checkEndDate"
                      color="info"
                      v-bind:key="i['value']"
                      :label="i['label']"
                      :value="i['value']"
                    ></v-radio>
                  </v-radio-group>
                  <v-menu
                    v-if="data.shopMonthlyFee.selectEndDate"
                    ref="menuEndDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        outlined
                        readonly
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        @blur="endDate = parseDate(dateEnd)"
                        v-bind="attrs"
                        v-on="on"
                        :rules="data.shopMonthlyFee.rulesEndDate"
                        clearable
                        @click:clear="dateEnd = null"
                        style="width: 200px"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="menuEndDate = false"
                      @change="
                        (dateEnd = formatDate(endDate)), CheckDateTime(startDate, endDate)
                      "
                      no-title
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <h4>สร้างบิลทุกวันที่ :</h4>
                </v-col>
                <v-col cols="6" md="9">
                  <v-select
                    class="mb-2 textbox-inline-block"
                    v-model="data.shopMonthlyFee.createInvoiceEveryDay"
                    :items="data.shopMonthlyFee.createInvoiceEveryDay_List"
                    :rules="data.shopMonthlyFee.rulesCreateInvoiceEveryDay"
                    item-text="day"
                    item-value="day"
                    outlined
                    autocomplete="off"
                    style="width: 80px"
                    dense
                    hide-details
                    single-line
                    return-object
                    persistent-hint
                  ></v-select>
                  <span class="pl-2">บาท/เดือน</span>
                </v-col>
                <v-col cols="12" md="3">
                  <h4>หมายเหตุ :</h4>
                </v-col>
                <v-col cols="12" md="9" class="mb-2">
                  ** ราคาที่ใส่นี้ต้องเป็นราคาที่รวม VAT แล้ว
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    dark
                    color="primary"
                    class="mr-1"
                    @click="SubmitShopMonthlyFee()"
                  >
                    <v-icon left>mdi-content-save</v-icon>
                    บันทึก
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          <span> ค่าบริการรายเดือน </span>
          <v-spacer />
          <v-btn
            :elevation="1"
            color="primary"
            class="pa-2"
            @click="UpdateShopMonthlyFee(data.shopMonthlyFee.dataset.length, false, null)"
          >
            <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
            เพิ่ม
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row row>
            <v-col class="pb-0" cols="12" md="12">
              <div class="overflow-y-auto packhai-max500-min100">
                <v-data-table
                  :headers="data.shopMonthlyFee.headers"
                  :items="data.shopMonthlyFee.dataset"
                  :items-per-page="9999999999"
                  item-key="key"
                  hide-default-footer
                  class="packhai-table packhai-border-table"
                >
                  <template v-slot:item.No="{ item }">
                    {{ formatMoney(data.shopMonthlyFee.dataset.indexOf(item) + 1) }}
                  </template>
                  <template v-slot:item.serviceCharge="{ item }">
                    {{ item.serviceCharge }}
                  </template>
                  <template v-slot:item.startDate="{ item }">
                    {{ formatDatetime(item.startDate) }}
                  </template>
                  <template v-slot:item.endDate="{ item }">
                    {{ formatDatetime(item.endDate) }}
                  </template>
                  <template v-slot:item.createInvoiceEveryDay="{ item }">
                    {{ item.createInvoiceEveryDay }}
                  </template>
                  <template v-slot:item.price="{ item }">
                    {{ formatMoney(item.price) }}
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <span>
                      <a
                        @click="
                          UpdateShopMonthlyFee(
                            data.shopMonthlyFee.dataset.indexOf(item),
                            true,
                            item
                          )
                        "
                      >
                        <v-icon size="16">fa-edit</v-icon>
                      </a>
                    </span>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <span>
                      <a
                        @click="
                          DeleteShopMonthlyFee(
                            item,
                            data.shopMonthlyFee.dataset.indexOf(item)
                          )
                        "
                      >
                        <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                      </a>
                    </span>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- ตั้งค่าร้านค้า -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-text>
          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">เงินเครดิตขั้นต่ำ</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <span
                >ตั้งค่าเพื่อหยุดแพ็คสินค้าของลูกค้าท่านในนี้ในกรณีที่เงินเครดิตต่ำกว่าที่กำหนด</span
              >
              <v-radio-group row class="mt-2" v-model="data.shop.minimumCredit">
                <v-radio
                  v-for="i in data.shop.minimumCredit_List"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
              <v-row row wrap>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-if="data.shop.minimumCredit == 1"
                    :value="data.shop.balanceLimit"
                    @change="(value) => (data.shop.balanceLimit = value)"
                    ref="datashopbalanceLimit"
                    :rules="[(v) => !!v || 'กรุณาระบุ']"
                    autocomplete="off"
                    hide-details
                    outlined
                    @keypress="isNumberWithDot($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">สร้างออเดอร์</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group
                row
                v-model="data.shop.isAllowAddOrderWhenStockBranchNotAvailable"
              >
                <v-radio
                  v-for="i in data.shop.isAllowAddOrderWhenStockBranchNotAvailable_List"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">ใช้ระบบจองสต๊อกหรือไม่</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group row v-model="data.shop.isUsingAllocateStock">
                <v-radio label="ใช้" :value="true"></v-radio>
                <v-radio label="ไม่ใช้" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">การตั้งค่าขณะแพ็กสินค้า</span>
            </v-col>
           
            <v-col class="py-3" cols="12">
              <table>
                <tr>
                  <td>
                    บังคับใส่ที่อยู่ผู้รับในกรณีที่ผู้รับอยู่ไม่สมบูรณ์
                  </td>
                  <td class="pl-2">
                    <v-radio-group row v-model="data.shop.isIgnoreReceiverAdress" hide-details>
                      <v-radio label="เปิดใช้" :value="false"></v-radio>
                      <v-radio label="ไม่เปิดใช้" :value="true"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>
                    บังคับใส่น้ำหนักรวมของพัสดุ
                  </td>
                  <td  class="pl-2">
                    <v-radio-group row v-model="data.shop.isIgnoreWeight" hide-details>
                      <v-radio label="เปิดใช้" :value="false"></v-radio>
                      <v-radio label="ไม่เปิดใช้" :value="true"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>
                    บังคับใส่ขนาดบรรจุภัณฑ์
                  </td>
                  <td  class="pl-2">
                    <v-radio-group row v-model="data.shop.isIgnoreDimension"   hide-details>
                      <v-radio label="เปิดใช้" :value="false"></v-radio>
                      <v-radio label="ไม่เปิดใช้" :value="true"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </table>
             
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black"
                >การสรุปยอดและสร้างใบแจ้งหนี้ ณ สิ้นวัน</span
              >
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group row v-model="data.shop.autoInvoiceType">
                <v-radio
                  v-for="i in data.shop.autoInvoice_List"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">ประเภทค่าบริการ</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group v-model="data.shop.serviceRateVatType" row>
                <v-radio
                  v-for="i in data.shop.serviceRateVatType_List"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">ใบปะหน้า</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group row v-model="data.shop.addressPrintType">
                <v-radio
                  v-for="i in data.shop.addressPrintType_List"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black"
                >ค่าบริการเชื่อมต่อ VRich</span
              >
            </v-col>
            <v-col class="py-3" cols="2">
              <v-text-field
                :value="data.shop.vrichTransactionFee"
                @change="(value) => (data.shop.vrichTransactionFee = Trim_value(value))"
                ref="datashopvrichTransactionFee"
                autocomplete="off"
                @keypress="isNumberWithDot($event)"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">ค่าบริการฝากสินค้า</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องปกติ พาเลท ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.palletePrice"
                    @change="(value) => (data.shop.palletePrice = Trim_value(value))"
                    ref="datashoppalletePrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องปกติ Rack ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.rackPrice"
                    @change="(value) => (data.shop.rackPrice = Trim_value(value))"
                    ref="datashoprackPrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องปกติ ชั้น ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.shelfPrice"
                    @change="(value) => (data.shop.shelfPrice = Trim_value(value))"
                    ref="datashopshelfPrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องเย็น พาเลท ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.coolPalletePrice"
                    @change="(value) => (data.shop.coolPalletePrice = Trim_value(value))"
                    ref="datashopcoolPalletePrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องเย็น Rack ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.coolRackPrice"
                    @change="(value) => (data.shop.coolRackPrice = Trim_value(value))"
                    ref="datashopcoolRackPrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">ห้องเย็น ชั้น ละ</p>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="data.shop.coolShelfPrice"
                    @change="(value) => (data.shop.coolShelfPrice = Trim_value(value))"
                    ref="datashopcoolShelfPrice"
                    autocomplete="off"
                    @keypress="isNumberWithDot($event)"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="pt-2 mb-0">บาท/เดือน</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">เวลาตัดรอบ</span>
            </v-col>
            <v-col class="py-3 packhai-showtime" cols="12">
              <div>
                <v-select
                  v-model="data.shop.cutOffTimeHour"
                  :items="data.shop.cutOffTimeHourList"
                  item-text="data"
                  item-value="data"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                ></v-select>
              </div>
              <b class="ma-3"> : </b>
              <div>
                <v-select
                  v-model="data.shop.cutOffTimeMinute"
                  :items="data.shop.cutOffTimeMinuteList"
                  item-text="data"
                  item-value="data"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                ></v-select>
              </div>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black">ค่าเช่าพื้นที่</span>
            </v-col>
            <v-col class="py-3" cols="12">
              <v-radio-group row wrap v-model="data.shop.autoCalculateSpaceDateTemp">
                <v-radio
                  v-for="i in data.shop.autoCalculateSpaceDateList"
                  color="info"
                  v-bind:key="i['value']"
                  :label="i['label']"
                  :value="i['value']"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="py-3" cols="1">
              <v-select
                class="mb-2 textbox-inline-block"
                v-if="data.shop.autoCalculateSpaceDateTemp == 1"
                v-model="data.shop.autoCalculateSpaceDateSelect"
                :items="data.shopMonthlyFee.createInvoiceEveryDay_List"
                item-text="day"
                item-value="day"
                outlined
                autocomplete="off"
                dense
                hide-details
                single-line
                return-object
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-divider v-if="data.shop.id != 0 && data.shop.id != null" />
            </v-col>
          </v-row>
          <!-- อัพเดทจำนวนสต๊อกคงเหลือ -->
          <v-row v-if="data.shop.id != 0 && data.shop.id != null">
            <v-col class="py-3" cols="12">
              <span style="font-size: 17.5px; color: black"
                >อัพเดทจำนวนสต๊อกคงเหลือไปยัง Marketplace</span
              >
              ( ระบบจะทำการอัพเดทสต๊อกให้ภายใน 15-20นาที )
            </v-col>
            <v-col class="py-3" cols="12">
              <v-btn
                :elevation="1"
                color="primary"
                class="pa-2"
                @click="handleUpdateStockClick()"
              >
                <v-icon size="17" class="mr-1">mdi-update</v-icon>
                อัพเดทสต๊อกสินค้า
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <!-- เลือกขนส่ง -->
    <v-card
      v-if="data.shop.id !== 0 && data.shop.id !== null"
      elevation="1"
      class="hidden-sm-and-down v-card-margin-bottom-30"
    >
      <v-card-title>
        <span class="my-3 ml-3">เลือกขนส่ง</span>
        <v-spacer />
        <v-btn
          :elevation="1"
          color="primary"
          @click="handleOpenDialogEditExpress(null, false)"
        >
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          <span class="fn-14"><b> เพิ่มขนส่ง </b></span>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div>
          <v-row class="mt-2 mx-0">
            <v-col cols="12" class="px-0">
              <!-- PC -->
              <v-data-table
                :headers="my_express.headers"
                :key="0"
                :items="my_express.dataset"
                hide-default-footer
                item-key="id"
                :items-per-page="10000"
                class="elevation-0 packhai-border-table"
              >
                <template v-slot:item.no="{ item }">
                  {{ my_express.dataset.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <img :src="item.expressCompanyLogo" width="60px" />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.Name="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <span v-if="item.isOasysAccount">
                        <img src="@/assets/logo_oasys.png" width="20px" height="20px" />{{
                          "Oasys account"
                        }}
                        <v-chip
                          v-show="item.isDefault"
                          class="chip-set-13"
                          color="teal"
                          dark
                        >
                          ค่าเริ่มต้น
                        </v-chip>
                      </span>
                      <span v-else
                        >{{ item.logisticAccountShortName }}
                        <v-chip
                          v-show="item.isDefault"
                          class="chip-set-13"
                          color="teal"
                          dark
                        >
                          ค่าเริ่มต้น
                        </v-chip></span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.COD="{ item }">
                  <v-icon
                    v-if="item.isAllowCod != null && item.isAllowCod"
                    color="success"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    v-else-if="item.isAllowCod != null && !item.isAllowCod"
                    color="error"
                    >mdi-close-circle</v-icon
                  >
                </template>
                <template v-slot:item.isActive="{ item }">
                  <v-row justify="center">
                    <a @click="handleUpdateIsActive(item)">
                      <v-switch
                        v-model="item.isActive"
                        readonly
                        color="success"
                      ></v-switch>
                    </a>
                  </v-row>
                </template>
                <template v-slot:item.viewDialogMyexpress="{ item }">
                  <a
                    v-if="
                      item.expressCompanyId != 0 &&
                      item.expressCompanyId != 8 &&
                      item.expressCompanyId != 9 &&
                      item.expressCompanyId != 15 &&
                      item.expressCompanyId != 16
                    "
                    @click="handleOpenDialogExpress(item, 1)"
                    >ตั้งค่าค่าขนส่ง</a
                  >
                </template>
                <template v-slot:item.viewDialogPackSetting="{ item }">
                  <a @click="handleOpenDialogExpress(item, 2)">ตั้งค่าการแพ็ค</a>
                </template>
                <template v-slot:item.statusMessage="{ item }">
                  <v-row>
                    <v-col cols="12" align="left">
                      <span v-if="item.statusMessage === 'พร้อมใช้งาน'"
                        ><v-icon size="10" color="success"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        พร้อมใช้งาน</span
                      >
                      <span
                        v-else-if="
                          item.statusMessage ===
                          'ยังไม่พร้อมใช้งาน ต้องตั้งค่า Account ขนส่งเพิ่มเติม'
                        "
                        ><v-icon size="10" color="error"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        ยังไม่พร้อมใช้งาน<br />ต้องตั้งค่า Account ขนส่งเพิ่มเติม</span
                      >
                      <span
                        v-else-if="
                          item.statusMessage === 'พร้อมใช้งานแล้วแต่ยังไม่เปิดใช้'
                        "
                        ><v-icon size="10" color="yellow"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        พร้อมใช้งาน<br />แต่ยังไม่เปิดใช้</span
                      >
                      <span v-else-if="item.statusMessage === 'ยังไม่พร้อมใช้งาน'"
                        ><v-icon size="10" color="error"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        item.statusMessage</span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.Edit="{ item }">
                  <a
                    v-if="
                      item.expressCompanyId != 0 &&
                      item.expressCompanyId != 8 &&
                      item.expressCompanyId != 9 &&
                      item.expressCompanyId != 15 &&
                      item.expressCompanyId != 16
                    "
                    @click="handleOpenDialogEditExpress(item, true)"
                    style="display: flex; flex-direction: column"
                  >
                    <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
                  </a>
                </template>
                <template v-slot:item.Delete="{ item }">
                  <a @click="handleDeleteMyExpreesClick(item.id)"
                    ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <!-- แก้ไขหรือเพิ่มขนส่งของฉัน -->
    <v-dialog
      v-model="my_express_edit.dialog"
      scrollable
      max-width="70%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- this.my_express.expressLogoDialog  -->
          <v-icon size="24" color="success">mdi-truck-fast</v-icon>
          <span v-if="!my_express_edit.isUpdate" class="ml-3">เพิ่มขนส่ง</span>
          <span v-else class="ml-3">แก้ไขขนส่ง</span>
          <v-spacer />
          <v-btn icon @click="my_express_edit.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="formAddExpress" lazy-validation>
            <v-row>
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ขนส่ง : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-if="!my_express_edit.isUpdate"
                  v-model="my_express_edit.express_selected"
                  :items="my_express_edit.expressName"
                  item-text="name"
                  item-value="id"
                  ref="refExpressAddbranchID"
                  @change="(value) => ExpressCompanyChanged(value)"
                  :rules="my_express_edit.rulesExpress"
                  required
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                >
                  <template slot="selection" slot-scope="data">
                    <img
                      v-if="data.item.logo != null"
                      :src="data.item.logo"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <img
                      v-if="data.item.logo != null"
                      :src="data.item.logo"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                </v-select>
                <span v-else>
                  <img
                    :src="
                      my_express_edit.express_selected == null
                        ? null
                        : my_express_edit.express_selected.logo
                    "
                    width="100px"
                  />
                </span>
                <span
                  v-if="
                    my_express_edit.expressValidateText !== null ||
                    my_express_edit.expressValidateText !== ''
                  "
                  style="color: red"
                  >{{ my_express_edit.expressValidateText }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ชื่อ : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.shopExpressName"
                  @change="(value) => (my_express_edit.shopExpressName = value)"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-show="
                my_express_edit.express_selected != null &&
                ![1].includes(my_express_edit.express_selected.id) &&
                my_express_edit.showHideAdd &&
                my_express_edit.showCOD
              "
            >
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> Account : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  ref="refAccountValidate"
                  :rules="my_express_edit.rulesAccount"
                  v-model="my_express_edit.accountID"
                  :items="my_express_edit.account_list"
                  item-text="shortName"
                  @change="(value) => SetDataLogicticAccountForadd(value)"
                  item-value="id"
                  :disabled="my_express_edit.isOasysAccount || my_express_edit.isUpdate"
                  required
                  outlined
                  hide-details
                  dense
                ></v-select>
                <span
                  v-if="
                    my_express_edit.accountID === null || my_express_edit.accountID === 0
                  "
                  style="color: red"
                  >{{ my_express_edit.accountValidateText }}</span
                >
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="pt-4 pb-2"
                align="left"
                v-if="!my_express_edit.isOasysAccount"
              >
                <a @click="handleShowDialogAddAccount()">
                  <span class="fn-14"> เพิ่ม acount ใหม่ </span>
                </a>
              </v-col>
            </v-row>

            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ต้นทุน COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.costCod"
                  @change="(value) => (my_express_edit.costCod = value)"
                  :disabled="
                    my_express_edit.isOasysAccount ||
                    my_express_edit.isCostPriceEditAble === false
                  "
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  @keypress="isNumberWithDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ราคาขาย COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="my_express_edit.CodPrice"
                  @change="(value) => (my_express_edit.CodPrice = value)"
                  autocomplete="off"
                  ref="refCodPersentAddbranchID"
                  outlined
                  :rules="my_express_edit.rulesCodPersent"
                  required
                  :disabled="!my_express_edit.isPriceEditAble"
                  dense
                  hide-details
                  @keypress="isNumberWithDot($event)"
                ></v-text-field>
                <span
                  v-if="
                    my_express_edit.CodPrice === null || my_express_edit.CodPrice === ''
                  "
                  style="color: red"
                  >{{ my_express_edit.codPersentValidateText }}</span
                >
              </v-col>
            </v-row>

            <!-- below code inter Express only  -->
            <!-- <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ServiceCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.serviceCodeID"
                  :items="interExpressOption.serviceCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesServiceCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> ServiceControlCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.serviceControlCodeID"
                  :items="interExpressOption.serviceControlCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesServiceControlCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> TemperatureControlCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.temperatureControlCodeID"
                  :items="interExpressOption.temperatureControlCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesTemperatureControlCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-show="interExpressOption.showHide">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> TemperatureTypeCode : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-select
                  v-model="interExpressOption.temperatureTypeCodeID"
                  :items="interExpressOption.temperatureTypeCode"
                  item-text="value"
                  item-value="id"
                  :rules="interExpressOption.rulesTemperatureTypeCode"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-col>
            </v-row> -->
            <v-row v-if="my_express_edit.showCOD">
              <v-col cols="12" md="2" class="pt-4 pb-2" align="left">
                <span class="fn-14"> COD : </span>
              </v-col>
              <v-col cols="12" md="8" class="pt-3 pb-2">
                <v-radio-group v-model="my_express_edit.isCod" row>
                  <v-radio label="เปิด" :value="true"></v-radio>
                  <v-radio label="ไม่เปิด" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-row row>
            <v-col cols="12" md="10" style="display: flex; flex-direction: row-reverse">
              <v-checkbox
                v-model="my_express_edit.isDefault"
                hide-details
                color="info"
                label="ตั้งเป็นค่าเริ่มต้น"
                style="align-items: center"
              ></v-checkbox>
            </v-col>
            <v-col class="" cols="12" md="2" align="left">
              <v-btn
                :elevation="1"
                color="primary"
                @click="handleUpdateBranchExpressClick()"
              >
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ผู้ใช้ -->
    <v-dialog
      v-model="my_express_edit.accountDIalog"
      scrollable
      max-width="50%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>เพิ่ม account ขนส่ง </span>
          <v-spacer />
          <v-btn icon @click="my_express_edit.accountDIalog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-form ref="DialogAddLogisticAccountForm" lazy-validation>
            <!-- <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3>  Shippop? : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isShippop"
                  row
                >
                  <v-radio
                    label="No"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="Yes"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3> การจ่ายเงิน : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isOMS"
                  row
                  @change="change_title()"
                >
                  <v-radio
                    label="จ่ายเงิน PACKHAI HQ"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="จ่ายเงินกับขนส่งโดยตรง"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3>ขนส่ง :</h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-select
                  v-model="dialog_data.expressCompanyID"
                  ref="expressCompanyID"
                  :items="dialog_data.expressCompanyList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  disabled
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                  @change="change_title()"
                >
                  <template slot="selection" slot-scope="data">
                    <img
                      v-if="data.item.logoPicSmall != null"
                      :src="data.item.logoPicSmall"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <img
                      v-if="data.item.logoPicSmall != null"
                      :src="data.item.logoPicSmall"
                      class="mr-2"
                      style="width: 60px"
                    />
                    {{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0">
              <v-col cols="12" md="3" class="pb-2" v-if="LoginEmail_or_ViP_ID_IsShow">
                <h4 v-if="vip_id_title == null">ViP_ID :</h4>
                <h4 v-else>{{ vip_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2" v-if="LoginEmail_or_ViP_ID_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_ID"
                  autocomplete="off"
                  ref="viP_ID"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pb-2" v-if="Key_or_ViP_Password_IsShow">
                <h4 v-if="vip_password_title == null">ViP_Password :</h4>
                <h4 v-else>{{ vip_password_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2" v-if="Key_or_ViP_Password_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_Password"
                  autocomplete="off"
                  ref="viP_Password"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              row
              wrap
              class="py-0"
              v-if="!dialog_data.isShippop && CustomerID_IsShow"
            >
              <v-col cols="12" md="3">
                <h4 v-if="customer_id_title == null">CustomerID :</h4>
                <h4 v-else>{{ customer_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-text-field
                  v-model="dialog_data.customerID"
                  autocomplete="off"
                  ref="refCustomerID"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0">
              <v-col cols="12" md="12" align="right">
                <v-btn dark color="primary" class="mr-1" @click="AddEditAccount()">
                  <v-icon left>mdi-content-save</v-icon>
                  บันทึก
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ราคาขนส่ง -->
    <v-dialog v-model="my_express.dialog" width="80%" scrollable persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>ราคาค่าขนส่ง</span>
          <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer />
          <v-btn icon @click="my_express.dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="formdata" lazy-validation>
            <v-data-table
              :headers="deliveryPrice.headers"
              :items="sortItemDeliveryPrice"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template
                v-for="(header, header_key) in deliveryPrice.headers"
                v-slot:[`header.${header.value}`]="{ header }"
              >
                <v-tooltip :key="header_key" v-slot:activator="{ on }">
                  <v-row v-if="!header.price">
                    <v-col cols="12">
                      {{ header.text }}
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <v-row align="center" justify="center" class="PB-B">
                        <a @click="handleOPPProvinceDialog(true, header)">
                          {{ header.text }} <v-icon>mdi-eye-outline</v-icon></a
                        >
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          v-for="(i, i_key) in header.price"
                          :key="i_key"
                          :class="i_key == 0 ? true : 'PB-L'"
                        >
                          <v-row align="center" justify="center">
                            {{ i.text }}
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tooltip>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td
                    v-for="(col, columnIndex) in deliveryPrice.headers"
                    :key="columnIndex"
                    class="text-center"
                  >
                    <span v-if="col.text == 'น้ำหนัก' && item.items[0].id !== 0">{{
                      item[col.value]
                    }}</span>
                    <v-text-field
                      v-else-if="col.text == 'น้ำหนัก' && item.items[0].id === 0"
                      :value="item.weight"
                      @change="(value) => (item.weight = value)"
                      autocomplete="off"
                      outlined
                      :rules="[(v) => !!v || 'กรุณาระบุ']"
                      hide-details
                      @keypress="isNumberWithDot($event)"
                      class="packhai-card-padding center-input pa-0"
                    ></v-text-field>
                    <span v-else-if="col.text == 'ขนาด' && item.items[0].id !== 0">{{
                      item.size
                    }}</span>
                    <v-text-field
                      v-else-if="col.text == 'ขนาด' && item.items[0].id === 0"
                      :value="item.size"
                      @change="(value) => (item.size = value)"
                      autocomplete="off"
                      outlined
                      hide-details
                      @keypress="isNumberWithDot($event)"
                      class="packhai-card-padding center-input pa-0"
                    ></v-text-field>
                    <div>
                      <span v-if="col.price">
                        <span v-for="(data, itemIndex) in item.items" :key="itemIndex">
                          <v-row v-if="col.provinceGroupId == data.provinceGroupID">
                            <v-col cols="6">
                              <v-text-field
                                :value="data.price"
                                @change="(value) => (data.price = value)"
                                :rules="[(v) => !!v || 'กรุณาระบุ']"
                                autocomplete="off"
                                outlined
                                hide-details
                                :disabled="!alldata.isPriceEditAble"
                                @keypress="isNumberWithDot($event)"
                                class="center-input"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                :value="data.costPrice"
                                @change="(value) => (data.costPrice = value)"
                                autocomplete="off"
                                outlined
                                hide-details
                                :disabled="!alldata.isCostPriceEditAble"
                                @keypress="isNumberWithDot($event)"
                                class="center-input"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <a
                      @click="
                        RemoveDeliveryPrice(deliveryPrice, deliveryPrice.indexOf(item))
                      "
                    >
                      <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                    </a>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <tr class="pt-5">
              <td cols="12">
                <v-btn
                  v-if="deliveryPrice.length != 0"
                  :elevation="1"
                  color="primary"
                  class="pa-2"
                  @click="AddDeliveryPrice(deliveryPrice)"
                >
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่ม
                </v-btn>
              </td>
            </tr>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-row row class="height-57 pa-2">
          <v-col class="mr-2" cols="12" align="right">
            <v-btn
              v-if="deliveryPrice.length != 0"
              :elevation="1"
              color="primary"
              @click="handleUpdateDeliveryPriceClick()"
            >
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- เพิ่มกลุ่มจังหวัด -->
    <v-dialog
      v-model="my_express.dialogProvince"
      scrollable
      width="515"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3" class="mr-3">mdi-group</v-icon>
          กลุ่มจังหวัด
          <v-spacer />
          <v-btn icon @click="my_express.dialogProvince = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="oppdialog" lazy-validation>
            <v-row>
              <v-col cols="3"> ชื่อกลุ่มจังหวัด: </v-col>
              <v-col cols="9">
                <v-text-field
                  :value="provinceGroup.description"
                  @change="(value) => (provinceGroup.description = Trim_value(value))"
                  ref="provinceGroupdescription"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pt-2"> เลือกจังหวัด: {{ total }} </v-col>
              <v-col cols="9" class="pt-2">
                <v-row class="select-province ma-0">
                  <v-col
                    cols="12"
                    id="scroll-target"
                    class="overflow-y-auto pa-2 height-515"
                  >
                    <v-checkbox
                      v-for="i in province"
                      v-model="selectProvince"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                      :disabled="i.disabled"
                      color="info"
                      class="mr-3"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ตั้งค่าการแพ็ค -->
    <v-dialog v-model="packsetting.packdialog" width="80%" scrollable persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- <v-icon size="30" color="red" class="mr-1">mdi-package-variant-closed</v-icon> -->
          ตั้งค่าค่าบริการแพ็คสินค้า
          <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer />
          <v-btn icon @click="packsetting.packdialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="DialogPackForm" lazy-validation>
            <v-card-text class="pt-0">
              <v-row row class="packhai-card-padding">
                <v-radio-group row wrap v-model="packsetting.packing">
                  <v-radio
                    v-for="i in packsetting.packing_List"
                    color="info"
                    v-bind:key="i['value']"
                    :label="i['label']"
                    :value="i['value']"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-row
                v-if="[3, 4].includes(packsetting.packing)"
                row
                wrap
                class="delivery-600"
              >
                <v-row
                  v-for="i in packsetting.priceAsClass"
                  :key="i.id"
                  row
                  wrap
                  class="packhai-card-padding py-1"
                >
                  <v-col cols="4">
                    <v-text-field
                      :value="i.start"
                      @change="(value) => (i.start = value)"
                      autocomplete="off"
                      label="ชิ้นที่"
                      outlined
                      @keypress="isNumberWNoDot($event)"
                      disabled
                      hide-details
                      style="width: 200px"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="i.end"
                      label="ถึง"
                      :ref="'refEnd' + i.id"
                      outlined
                      autocomplete="off"
                      @keypress="isNumberWNoDot($event)"
                      @change="
                        ChangeFollowPices(
                          packsetting.priceAsClass,
                          packsetting.priceAsClass.indexOf(i)
                        )
                      "
                      hide-details
                      style="width: 200px"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      :value="i.price"
                      @change="(value) => (i.price = value)"
                      :rules="[(v) => CheckEmpty(v) || 'กรุณาระบุ']"
                      autocomplete="off"
                      label="ราคา"
                      outlined
                      hide-details
                      style="width: 200px"
                      @keypress="isNumberWithDot($event)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-row>
              <v-row row wrap>
                <v-col cols="12">
                  <v-data-table
                    :headers="CheckPackingHeader(packsetting.packing)"
                    :items="packsetting.postboxlist"
                    :items-per-page="9999999999"
                    item-key="key"
                    hide-default-footer
                  >
                    <template v-slot:item.orderNo="{ item }">
                      <v-text-field
                        v-model="item.orderNo"
                        @change="
                          SetOrderNo(
                            packsetting.postboxlist,
                            packsetting.postboxlist.indexOf(item)
                          )
                        "
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.name }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.name"
                        @change="(value) => (item.name = Trim_value(value))"
                        :rules="[(v) => CheckEmpty(v) || 'กรุณาระบุ']"
                        ref="FormPackName"
                        autocomplete="off"
                        hide-details
                        outlined
                        class="packhai-card-padding pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic1="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic1 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic1"
                        @change="(value) => (item.cubic1 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic2="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic2 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic2"
                        @change="(value) => (item.cubic2 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.cubic3="{ item }">
                      <span
                        v-if="
                          (item.shopId == null && item.parentID != null) || item.id != 0
                        "
                        >{{ item.cubic3 }}</span
                      >
                      <v-text-field
                        v-else
                        :value="item.cubic3"
                        @change="(value) => (item.cubic3 = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWNoDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.boxCostPrice="{ item }">
                      <v-text-field
                        :value="item.boxCostPrice"
                        @change="
                          (value) => (item.boxCostPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.boxSellPrice="{ item }">
                      <v-text-field
                        :value="item.boxSellPrice"
                        @change="
                          (value) => (item.boxSellPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.packPrice="{ item }">
                      <v-text-field
                        :value="item.packPrice"
                        @change="(value) => (item.packPrice = value == '' ? null : value)"
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.packAndBoxPrice="{ item }">
                      <v-text-field
                        :value="item.packAndBoxPrice"
                        @change="
                          (value) => (item.packAndBoxPrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.bubblePrice="{ item }">
                      <v-text-field
                        :value="item.bubblePrice"
                        @change="
                          (value) => (item.bubblePrice = value == '' ? null : value)
                        "
                        autocomplete="off"
                        @keypress="isNumberWithDot($event)"
                        hide-details
                        outlined
                        class="packhai-card-padding center-input pa-0"
                      />
                    </template>
                    <template v-slot:item.delete="{ item }">
                      <a
                        @click="
                          RemovePostBox(
                            packsetting.postboxlist,
                            packsetting.postboxlist.indexOf(item)
                          )
                        "
                      >
                        <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="pt-5">
                <v-col cols="12">
                  <v-btn
                    :elevation="1"
                    color="primary"
                    class="pa-2"
                    @click="AddPostBox(packsetting.postboxlist)"
                  >
                    <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                    เพิ่ม
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-row row class="height-57 pa-2">
          <v-col class="mr-2" cols="12" align="right">
            <v-btn :elevation="1" color="primary" @click="handleUpdatePackSettingClick()">
              <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- warehouseAccees -->
    <v-card v-if="data.shop.id > 0" elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> <span class="mt-3 ml-3">คลังที่เข้าถึงได้ </span> </v-card-title>
      <v-card-text>
        <v-row class="mx-0">
          <v-col cols="12">
            <v-data-table
              v-model="warehouseAccess.selectWarehouse"
              :headers="warehouseAccess.headers"
              :items="warehouseAccess.datawarehouse"
              :items-per-page="9999999999"
              show-select
              hide-default-footer
              class="packhai-table packhai-border-table"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(warehouseAccess.datawarehouse.indexOf(item) + 1) }}
              </template>
              <template v-slot:item.address="{ item }">
                <span v-if="item.postcodemappingID != null">
                  {{ item.fullAddress }}
                </span>
                <span v-else> {{ item.address }} </span>
              </template>
              <template v-slot:item.openOrder="{ item }">
                <v-row justify="center">
                  <v-radio-group
                    v-model="item.IsAllowAddOrderWhenStockShortage"
                    row
                    required
                    class="mb-2"
                    dense
                    hide-details
                  >
                    <v-radio :value="true">
                      <template v-slot:label> <span> เปิด </span> </template>
                    </v-radio>
                    <v-radio :value="false">
                      <template v-slot:label> <span>ปิด</span> </template>
                    </v-radio>
                  </v-radio-group>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- เงื่อนไขเพิ่มเติม -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> เงื่อนไขเพิ่มเติม </v-card-title>
      <v-card-text>
        <v-textarea
          :value="data.shop.additionalCondition"
          @change="(value) => (data.shop.additionalCondition = value)"
          autocomplete="off"
          outlined
        />
      </v-card-text>
    </v-card>

    <!-- ประเภทลูกค้า -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> ประเภทลูกค้า </v-card-title>
      <v-card-text class="pt-0">
        <v-radio-group row v-model="data.shop.isActive">
          <v-radio
            v-for="i in data.shop.isActive_List"
            color="info"
            v-bind:key="i['value']"
            :label="i['label']"
            :value="i['value']"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>

    <!-- บันทึก -->
    <v-card elevation="1">
      <v-card-actions>
        <v-spacer />
        <v-btn :elevation="1" color="success" class="pa-2" outlined @click="ShopUpdate()">
          <v-icon size="14" class="mr-1">fa-solid fa-floppy-disk</v-icon>
          บันทึก
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2"; 
import Loading from "@/website/components/Loading";
import {
  generalService_dotnet,
  shopService_dotnet,
  branchService_dotnet,
  logisticService_dotnet,
  ecomService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  isNumberWithDot,
  isNumberWNoDot,
  formatMoney,
  Trim_value,
  Trim_rules,
  formatDatetime,
  formatDate_thai,
  formatDate_eng,
  everyDay,
  GetHourList,
  GetMinuteList,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    page_loading: true,
    loading: false,
    postcode_mapping: null,

    //ขนส่งใหม่
    alldata: [],
    deliveryPrice: [],
    isUpdateAllToShop: false,
    removeDeliveryPrice: [],

    // province
    total: 0,
    provinceIdList: [],
    selectProvince: [],
    province: [],
    provinceTemp: [],
    provinceGroupItem: [],
    provinceGroup: [],
    my_express: {
      headers: [
        { text: "ลำดับ", align: "left", sortable: false, value: "no", width: "50px" },
        { text: "แก้ไข", align: "left", sortable: false, value: "Edit", width: "50px" },
        {
          text: "ตั้งค่าการแพ็ค",
          align: "left",
          sortable: false,
          value: "viewDialogPackSetting",
          width: "140px",
        },
        {
          text: "ดูค่าขนส่ง",
          align: "left",
          sortable: false,
          value: "viewDialogMyexpress",
          width: "140px",
        },
        {
          text: "ขนส่ง",
          align: "left",
          sortable: false,
          value: "photoLink",
          width: "120px",
        },
        {
          text: "Account",
          align: "left",
          sortable: false,
          value: "Name",
          width: "200px",
        },
        {
          text: "ชื่อขนส่ง",
          align: "left",
          sortable: false,
          value: "shopExpressName",
          width: "130px",
        },
        { text: "COD", align: "left", sortable: false, value: "COD", width: "50px" },
        {
          text: "ราคาขาย COD",
          align: "left",
          sortable: false,
          value: "codpercent",
          width: "100px",
        },
        {
          text: "ต้นทุน COD",
          align: "left",
          sortable: false,
          value: "codcost",
          width: "50px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusMessage",
          width: "170px",
        },
        {
          text: "เปิดใช้งาน",
          align: "left",
          sortable: false,
          value: "isActive",
          width: "80px",
        },
        { text: "ลบ", align: "left", sortable: false, value: "Delete", width: "50px" },
      ],
      dataset: [],
      dialog: false,
      expressLogoDialog: "",
      filterDialogShow: "",
      BranchExpressId: null,
      dialogProvince: false,
      isUpdate: false,
    },
    my_express_edit: {
      dialog: false,
      isUpdate: false,
      Logo: null,
      isDefault: false,
      branchExpressID: 0,
      shopExpressName: null,
      express_selected: null,
      isCod: true,
      Hqaccount: null,
      expressName: null,
      accountID: null,
      account_list: [],
      costCod: null,
      CodPrice: null,
      isOasysAccount: null,
      dataAccount_list: [],
      isCostPriceEditAble: null,
      isPriceEditAble: false,
      accountDIalog: false,
      showHideAdd: false,
      showCOD: false,
   
      rulesExpress: [],
      rulesCodPersent: [],
      rulesAccount: [],
      accountValidateText: "",
      expressValidateText: "",
      codPersentValidateText: "",
    },
    interExpressOption: {
      showHide: false,
      serviceCodeID: null,
      rulesServiceCode: [],
      rulesServiceControlCode: [],
      rulesTemperatureControlCode: [],
      rulesTemperatureTypeCode: [],
      serviceCodeValidateText: "",
      serviceControlCodeValidateText: "",
      temperatureControlCodeValidateText: "",
      temperatureTypeCodeValidateText: "",
      serviceCode: [
        { id: "ส่งด่วน", value: "ส่งด่วน" },
        { id: "ส่งด่วนภายในวัน", value: "ส่งด่วนภายในวัน" },
        { id: "ส่งธรรมดา", value: "ส่งธรรมดา" },
      ],
      serviceControlCodeID: null,
      serviceControlCode: [
        { id: "บริการขนส่งสินค้าทั่วไป", value: "บริการขนส่งสินค้าทั่วไป" },
        { id: "บริการขนส่งผัก-ผลไม้", value: "บริการขนส่งผัก-ผลไม้" },
        { id: "บริการขนส่งกล่องโฟม", value: "บริการขนส่งกล่องโฟม" },
        { id: "บริการแพ็คพร้อมส่ง", value: "บริการแพ็คพร้อมส่ง" },
        { id: "บริการขนส่งกล่องกระดาษเย็น", value: "บริการขนส่งกล่องกระดาษเย็น" },
      ],
      temperatureControlCodeID: null,
      temperatureControlCode: [
        { id: "สินค้าทั่วไป", value: "สินค้าทั่วไป" },
        { id: "สินค้าควบคุมอุณหภูมิ", value: "สินค้าควบคุมอุณหภูมิ" },
      ],
      temperatureTypeCodeID: null,
      temperatureTypeCode: [
        {
          id: "สินค้าควบคุมอุณหภูมิที่ 2-8 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ 2-8 องศา",
        },
        {
          id: "สินค้าควบคุมอุณหภูมิที่ต่ำกว่า -15 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ต่ำกว่า -15 องศา",
        },
        { id: "ขนส่งสินค้าทั่วไป", value: "ขนส่งสินค้าทั่วไป" },
        {
          id: "สินค้าควบคุมอุณหภูมิที่ 15 - 25 องศา",
          value: "สินค้าควบคุมอุณหภูมิที่ 15 - 25 องศา",
        },
      ],
    },

    // packsetting
    packsetting: {
      packdialog: false,
      postboxlist: [],
      packing: 3,
      packing_List: [
        { label: "ตามจำนวนชิ้น", value: 3 },
        { label: "ตาม SKU", value: 4 },
        { label: "ตามขนาดกล่อง", value: 2 },
        { label: "คงที่", value: 1 },
      ],
      priceAsClass: [
        { id: 0, start: 1, end: 1, price: 0 },
        { id: 1, start: 2, end: null, price: 3 },
      ],
      removeItem: [],
    },
    //dialog เพิ่ม account
    dialog_data: {
      id: 0,
      expressCompanyID: null,
      expressCompanyList: null,
      shopID: null,
      shopList: null,
      isShippop: false,
      viP_ID: null,
      viP_Password: null,
      customerID: null,
      isOMS: true,
      isNeedCollectDeliveryPrice: false,
      isPickup: false,
    },
    LoginEmail_or_ViP_ID_IsShow: true,
    Key_or_ViP_Password_IsShow: true,
    CustomerID_IsShow: true,
    vip_id_title: null,
    vip_password_title: null,
    customer_id_title: null,
    ///
    data: {
      shop: {
        branchId: parseInt(localStorage.getItem("Branch_BranchID")),
        id: 0,
        name: null,
        email: null,
        createdDatetime: new Date(),
        isIgnoreDimension:false,
        isIgnoreReceiverAdress:false,
        isIgnoreWeight:false,

        // เงินเครดิตขั้นต่ำ
        minimumCredit_List: [
          { label: "ไม่มีขั้นต่ำ", value: 0 },
          { label: "มีขั้นต่ำ", value: 1 },
        ],
        minimumCredit: 0,
        balanceLimit: parseFloat(0).toFixed(2), //แพ็คสินค้าได้ Balance ไม่ต้ำกว่า BalanceLimit

        // สร้างออเดอร์
        isAllowAddOrderWhenStockBranchNotAvailable_List: [
          { label: "ไม่ให้เปิดออเดอร์ถ้าสต๊อกสินค้าไม่เพียงพอ", value: 0 },
          { label: "เปิดออเดอร์ได้ในกรณีสต๊อกไม่เพียงพอ", value: 1 },
        ],
        isAllowAddOrderWhenStockBranchNotAvailable: 0,

        // การสร้างใบแจ้งหนี้
        autoInvoice_List: [
          { value: 1, label: "ไม่ต้องตัดเงิน ไม่ต้องสร้างใบแจ้งหนี้" },
          { value: 2, label: "ตัดเงินทุกวัน  แต่ยังไม่ต้องสร้างใบแจ้งหนี้" },
          { value: 3, label: "ตัดเงินทุกวัน  และสร้างใบแจ้งหนีทันที" },
        ],
        autoInvoiceType: 2,

        // ประเภทค่าบริการ
        serviceRateVatType_List: [
          { label: "รวมภาษี", value: 1 },
          { label: "ยังไม่รวมภาษี", value: 2 },
        ],
        serviceRateVatType: 1,

        // ใบปะหน้า
        addressPrintType_List: [
          { value: 1, label: "ใช้ PACKHAI Fulfillment" },
          { value: 2, label: "ใช้ชื่อลูกค้าแต่ที่อยู่ของ PACKHAI Fulfillment" },
          { value: 3, label: "ใช้ชื่อของร้านค้าและที่อยู่ของร้านค้า" },
        ],
        addressPrintType: 2,

        // ค่าบริการเชื่อมต่อ VRich
        vrichTransactionFee: null,

        // ค่าบริการฝากสินค้า
        rackPrice: null,
        shelfPrice: null,
        palletePrice: null,
        coolRackPrice: null,
        coolShelfPrice: null,
        coolPalletePrice: null,

        // เวลาตัดรอบ
        cutOffTimeHourList: GetHourList(),
        cutOffTimeHour: { data: "12" },
        cutOffTimeMinuteList: GetMinuteList(),
        cutOffTimeMinute: { data: "00" },
        cutOffTime: null,

        // ค่าเช่าพื้น
        autoCalculateSpaceDateList: [
          { value: 0, label: "คำนวนค่าเช่าพื้นที่อัตโนมัติทุกสิ้นเดือน" },
          { value: 1, label: "คำนวนค่าเช่าพื้นที่อัตโนมัติทุกวันที่" },
          { value: 2, label: "ไม่ต้องคำนวนค่าเช่าพื้นที่" },
        ],
        autoCalculateSpaceDateTemp: 0,
        autoCalculateSpaceDateSelect: { day: 1 },
        autoCalculateSpaceDate: 31,

        // เงื่อนไขเพิ่มเติม
        additionalCondition: null,

        // ประเภทลูกค้า
        isActive_List: [
          { label: "สร้างใบเสนอราคา", value: 0 },
          { label: "ใช้บริการ", value: 1 },
        ],

        isActive: 1,

        isDelete: false,
        addOrderMode: 1,
        isHideAddress: false,
        defaultExpressCompanyID: 1,
        isNeedImportFile: false,
        customerType: 1,
        balance: parseFloat(0).toFixed(2),
        codWallet: parseFloat(0).toFixed(2),
        agentBalance: parseFloat(0).toFixed(2),
        isUsingAllocateStock: false,
      },
      shopAddress: {
        id: 0,
        shopId: 0,
        name: null,
        isDefault: true,
        phone: null,
        address: null,
        postcodeMapping: null,
        postcodeMappingId: null,
        tumbon: null,
        amphoe: null,
        province: null,
        postcode: null,
      },
      staffShop: {
        id: 0,
        shopId: 0,
        name: null,
        phone: null,
        username: null,
        password: null,
        isMasterUser: true,
        showpassword: false,
        token: null,
      },
      shopAddressInvoice: {
        id: 0,
        shopId: 0,
        name: null,
        isDefault: true,
        isDeleted: false,
        phone: null,
        idCardNumber: null,
        address: null,
        postcodeMapping: null,
        postcodeMappingId: null,
      },
      shopMonthlyFee: {
        id: 0,
        serviceCharge: null,
        price: 0,
        startDate: null,
        endDate: null,
        createInvoiceEveryDay: null,

        createInvoiceEveryDay_List: [],
        dialog: false,
        index: 0,
        pUpdate: false,
        checkEndDate: [
          { value: 0, label: "ไม่มีสิ้นสุด" },
          { value: 1, label: "สิ้นสุดวันที่" },
        ],
        selectEndDate: 0,

        rulesServiceCharge: [],
        rulesPrice: [],
        rulesStartDate: [],
        rulesEndDate: [],
        rulesCreateInvoiceEveryDay: [],

        headers: [
          { align: "center", text: "No", value: "No", sortable: false, width: "70px" },
          { align: "left", text: "ค่าบริการ", value: "serviceCharge", sortable: false },
          {
            align: "center",
            text: "วันที่เริ่ม",
            value: "startDate",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "วันที่สิ้นสุด",
            value: "endDate",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "วันที่สร้างบิล",
            value: "createInvoiceEveryDay",
            sortable: false,
            width: "130px",
          },
          {
            align: "right",
            text: "ยอด",
            value: "price",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "แก้ไข",
            value: "edit",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "100px",
          },
        ],
        dataset: [],
      },

      // เลือกขนส่ง
      express: {
        express_List: [],
        selectExpress: [1, 8, 9],
        tabExpress_List: [],
        tabExpress: null,
      },
    },
    // เพิ่มคลัง
    warehouseAccess: {
      headers: [
        { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
        {
          text: "รหัสคลัง",
          align: "center",
          value: "warehouseCode",
          sortable: false,
          width: "150px",
        },
        { text: "ชื่อ", align: "left", value: "name", sortable: false },
        { text: "ที่อยู่", align: "left", value: "fullAddress", sortable: false },
        {
          align: "center",
          text: "เปิดออเดอร์ได้หากสต๊อกไม่เพียงพอ",
          value: "openOrder",
          sortable: false,
          width: "250px",
        },
      ],
      datawarehouse: [],
      selectWarehouse: [],
    },
    // วันที่ ของ ค่าบริการรายเดือน
    startDate: new Date().toISOString().substr(0, 10),
    dateStart: null,
    menuStartDate: false,
    endDate: null,
    dateEnd: null,
    menuEndDate: false,

    // dialog
    opp: {
      dialog: false,
      isUpdate: false,
    },

    logistic_account_for_add: [],
    // province
    // total: 0,
    // provinceIdList: [],
    // selectProvince: [],
    // province: [],
    // provinceTemp: [],
    // provinceGroup: {
    //   branchId: localStorage.getItem("Branch_BranchID"),
    //   shopId: null,
    //   id: 0,
    //   description: null,
    //   rulesDescription: [],
    //   expressCompanyId: 0,
    // },
    // provinceGroupItem: [],
  }),
  computed: {
    sortItemDeliveryPrice() {
      return [...this.deliveryPrice].sort((a, b) => {
        if (a.weight === "" || b.weight === "") {
          return 0;
        }
        return a.weight - b.weight;
      });
    },
  },
  watch: {
    selectProvince() {
      var check = false;
      this.total = 0;
      this.provinceIdList = [];
      for (var i = 0; i < this.province.length; i++) {
        if (!this.province[i].disabled) {
          check = this.selectProvince.includes(this.province[i].id);
          if (check) {
            this.total += 1;
            this.provinceIdList.push(this.province[i].id);
          }
        }
      }
    },

    "my_express_edit.accountDIalog": {
      handler(value) {
        if (!value) {
          if (this.my_express_edit.isUpdate) {
            this.my_express_edit.dialog = true;
          } else {
            this.handleOpenDialogEditExpress(null, false);
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    this.page_loading = false;
    document.title = this.$router.history.current.meta.title;
    var shopId = parseInt(localStorage.getItem("CreateShop_ShopID"));
    if (shopId == 0) {
      this.navigete = "สร้างร้านค้า";
      await this.Load_data();
    } else {
      this.navigete = "แก้ไขร้านค้า";
      this.data.shop.id = shopId;
      await this.get_branch_express();
      await this.GetWarehouse();
      await this.Load_data_edit();
    }
    this.shopId = this.data.shop.id;
    document.title = this.navigete;
  },
  methods: {
    async Load_data() {
      await axios
        .all([
          axios.post(
            generalService_dotnet + "General/get-postcodeMapping",
            {},
            { headers: this.header_token }
          ),

          axios.post(
            logisticService_dotnet + "Logistic/get-generalsetting-list",
            {},
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((postcodeMappingList, generalSettingList) => {
            var shopExpressList = [];
            this.postcode_mapping = postcodeMappingList.data;
          })
        );

      // วันที่ 1-31
      this.data.shopMonthlyFee.createInvoiceEveryDay_List = this.everyDay();
    },

    async Load_data_edit() {
      await axios
        .all([
          axios.post(
            generalService_dotnet + "General/get-postcodeMapping",
            {},
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "Shop/get-shop",
            {
              shopId: this.data.shop.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "Shop/get-default-shop-address",
            {
              shopId: this.data.shop.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "Shop/get-master-user",
            {
              shopId: this.data.shop.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "Shop/get-default-shop-address-invoice",
            {
              shopId: this.data.shop.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            shopService_dotnet + "Shop/get-shop-monthlyfee",
            {
              shopId: this.data.shop.id,
            },
            { headers: this.header_token }
          ),

          axios.post(
            logisticService_dotnet + "Logistic/get-generalsetting-list",
            {},
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread(
            (
              postcodeMappingList,
              shop,
              shopAddress,
              staffShop,
              shopAddressInvoice,
              shopMonthlyfee,

              generalSettingList
            ) => {
              this.postcode_mapping = postcodeMappingList.data;
              this.setShop(shop.data);
              this.setShopAddress(shopAddress.data);
              this.setStaffShop(staffShop.data);
              this.setShopAddressInvoice(shopAddressInvoice.data);
              this.setShopMonthlyfee(shopMonthlyfee.data);
            }
          )
        );
      // วันที่ 1-31
      this.data.shopMonthlyFee.createInvoiceEveryDay_List = this.everyDay();
    },
    /// ตั้งค่าขนส่งใหม่
    async get_branch_express() {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-shop-express-list?ShopID=" +
          localStorage.getItem("CreateShop_ShopID") +
          "&BranchID=" +
          localStorage.getItem("Branch_BranchID"),
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        this.my_express.dataset = response.data.shopExpressList;
      }
    },
    async get_logistic_account_edit(expressBId) {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-shop-express-logistic-account-for-edit?ShopExpressID=" +
          expressBId,
        { headers: this.header_token }
      );

      if (response.data.status === "success") {
        this.my_express_edit.account_list = [];
        this.my_express_edit.accountID = null;
        for (var x in response.data.accountList) {
          this.my_express_edit.account_list.push(response.data.accountList[x]);
        }
        return true;
      } else {
        return false;
      }
    },
    async get_branch_express_for_add() {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-shop-express-for-add?BranchID=" +
          localStorage.getItem("Branch_BranchID") +
          "&ShopID=" +
          localStorage.getItem("CreateShop_ShopID"),
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        return response.data.expressList;
      }
    },
    async get_branch_express_list() {
      let response = await axios.post(
        logisticService_dotnet + "Logistic/get-express-list",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status === 200) {
        return response.data;
      }
    },
    async get_branch_express_delivery_price(expressBId) {
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-shop-express-delivery-price?ShopExpressID=" +
          expressBId,
        { headers: this.header_token }
      );
      this.loading = true;
      if (response.data.status === "success") {
        this.loading = false;
        this.my_express.shopExpressId = expressBId;
        this.alldata = response.data;
        this.deliveryPrice = response.data.deliveryPrices;
        this.provinceGroup = response.data.provinceGroupList;
        this.provinceGroupItem = response.data.provinceGroupItemList;
        await this.SetDeliveryPrice();
      }
    },
    async get_branch_express_postbox(expressBId) {
      this.my_express.shopExpressId = expressBId;
      let response = await axios.get(
        logisticService_dotnet +
          "BranchExpress/get-shop-express-postbox?ShopExpressID=" +
          expressBId,
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.data.status === "success") {
        this.packsetting.postboxlist = response.data.postboxList;
        this.packsetting.packing = response.data.packingChargedType;
        this.packsetting.priceAsClass =
          response.data.packingStep === null || response.data.packingStep.length === 0
            ? [
                { id: 0, start: 1, end: "1", price: 0, no: 1 },
                { id: 1, start: 2, end: "", price: 3, no: 2 },
              ]
            : response.data.packingStep;
      }
    },
    async handleDeleteMyExpreesClick(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ยืนยันการลบขนส่งนี้ใช่ไหม",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.get(
            logisticService_dotnet +
              "BranchExpress/delete-shop-express?ShopExpressID=" +
              item,
            { headers: this.header_token }
          );
          this.loading = false;
          if (response.data.status === "success") {
            await this.get_branch_express();
          }
        }
      });
    },
    async handleUpdateIsActive(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ยืนยันการเลือก",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.post(
            logisticService_dotnet + "BranchExpress/update-active-shop-express",
            {
              shopExpressID: item.id,
              isActive: !item.isActive,
            },
            { headers: this.header_token }
          );
          this.loading = false;
          if (response.data.status === "success") {
            await this.get_branch_express();
          }
        }
      });
    },
    async handleOpenDialogEditExpress(item, isUpdate) {
      this.loading = true;
      this.my_express_edit.isUpdate = isUpdate;
      this.my_express_edit.isDefault = false;
      await this.ClearRules();
      if (isUpdate) {
        var IsPass = await this.get_logistic_account_edit(item.id);
        if (!IsPass) {
          this.loading = false;
          return;
        }
        this.my_express_edit.dialog = true;
        this.my_express.shopExpressId = item.id;
        this.my_express_edit.isDefault = item.isDefault ? true : false;
        this.my_express_edit.shopExpressName = item.shopExpressName;
        this.my_express_edit.expressName = [
          {
            id: item.expressCompanyId,
            name: item.expressCompanyName,
            logo: item.expressCompanyLogo,
          },
        ];
        this.my_express_edit.express_selected = this.my_express_edit.expressName[0];
        this.my_express_edit.accountName = item.logisticAccountShortName;
        this.my_express_edit.costCod = item.codcost; //ใส่await ไว้ตอนเพิ่มแล้วมากดแก้ไข ค่าเปลี่ยนช้าทำให้แสดงเป็นnull
        this.my_express_edit.CodPrice = item.codpercent;
        this.my_express_edit.isOasysAccount = item.isOasysAccount;

        this.my_express_edit.branchExpressID = item.branchExpressID; // อันนี้คือ branchexpress จริงๆ
        this.my_express_edit.showHideAdd = item.expressCompanyId !== 1 ? true : false;
        this.my_express_edit.showCOD = true;
        this.my_express_edit.isCostPriceEditAble = item.isCostPriceEditAble;
        this.my_express_edit.isPriceEditAble = item.isPriceEditAble;
        console.log(this.my_express_edit.isPriceEditAble);
        this.my_express_edit.isCod = item.isAllowCod;

        // interexpress only
        this.interExpressOption.showHide = item.expressCompanyId === 18 ? true : false;
        this.interExpressOption.serviceCodeID = item.servicecode;
        this.interExpressOption.serviceControlCodeID = item.serviceControlCode;
        this.interExpressOption.temperatureControlCodeID = item.temperatureControlCode;
        this.interExpressOption.temperatureTypeCodeID = item.temperatureTypeCode;
        this.my_express_edit.hqLogisticAccountID = item.hqLogisticAccountID;
        this.my_express_edit.branchLogisticAccountID = item.branchLogisticAccountID;
        this.my_express_edit.shopLogisticAccountID = item.shopLogisticAccountID;

        if (item.shopLogisticAccountID != null) {
          this.my_express_edit.accountID = item.shopLogisticAccountID;
        } else if (item.branchLogisticAccountID != null) {
          this.my_express_edit.accountID = item.branchLogisticAccountID;
        } else if (item.hqLogisticAccountID != null) {
          this.my_express_edit.accountID = item.hqLogisticAccountID;
        }
      } else {
        // let expressResponse =  await this.get_branch_express_list()
        this.my_express_edit.dialog = true;
        this.logistic_account_for_add = await this.get_branch_express_for_add();
        this.my_express_edit.branchExpressID = null;
        this.my_express_edit.shopExpressName = null;
        this.my_express_edit.accountName = null;
        (this.my_express_edit.costCod = null), (this.my_express_edit.CodPrice = null);
        this.my_express_edit.Logo = null;
        this.my_express_edit.account_list = [];
        this.my_express_edit.accountID = null;
        this.my_express_edit.isCod = true;
        this.my_express_edit.isOasysAccount = null;
        this.my_express.shopExpressId = null;
        this.interExpressOption.serviceCodeID = null;
        this.interExpressOption.serviceControlCodeID = null;
        this.interExpressOption.temperatureControlCodeID = null;
        this.interExpressOption.temperatureTypeCodeID = null;
        this.interExpressOption.showHide = false;
        this.my_express_edit.isCostPriceEditAble = null;
        this.my_express_edit.isPriceEditAble = true;
        this.my_express_edit.showHideAdd = false;
        this.my_express_edit.showCOD = true;
        this.my_express_edit.hqLogisticAccountID = null;
        this.my_express_edit.branchLogisticAccountID = null;
        this.my_express_edit.shopLogisticAccountID = null;

        this.my_express_edit.expressName = [
          { id: -1, name: "--กรุณาเลือกขนส่ง--", logo: null },
        ];
        for (var x in this.logistic_account_for_add) {
          this.my_express_edit.expressName.push({
            id: this.logistic_account_for_add[x].expressCompanyID,
            name: this.logistic_account_for_add[x].expressCompanyName,
            logo: this.logistic_account_for_add[x].expressCompanyLogo,
          });
        }
        this.my_express_edit.express_selected = this.my_express_edit.expressName[0];
        // this.my_express_edit.expressName = expressResponse.filter(
        //         (x) => x.isShowInPackForm && ![19,20,21,22,23].includes(x.id)
        //       );
        //       this.my_express_edit.expressName.splice(0, 0, {
        //         id: 0,
        //         name: "--ทั้งหมด--",
        //       });
        //         this.$watch(
        //           () => this.my_express_edit.express_selected.id,
        //           (value) => {
        //             if (this.my_express_edit.express_selected.id == -1) {
        //               return;
        //             }
        //             var data = for_add.filter((x) => x.expressCompanyID === value);

        //             this.my_express_edit.account_list = [];
        //             this.my_express_edit.accountID = null;
        //             (this.my_express_edit.costCod = null), (this.my_express_edit.CodPrice = null);
        //             this.my_express_edit.expressValidateText =
        //               value === -1 ? this.my_express_edit.expressValidateText : "";
        //             this.my_express_edit.showHideAdd = value === -1 || value === 1 ? false : true;
        //             //inter express only
        //             this.interExpressOption.showHide = value === 18 ? true : false;
        //             //3party express showCOD
        //             this.my_express_edit.showCOD = ![0, 8, 9, 15].includes(value); // ให้show COD เฉพาะขนส่งที่ไม่ได้มาจาก marketplace
        // console.log(this.my_express_edit);
        //             this.my_express_edit.shopExpressName =
        //               value === -1
        //                 ? null
        //                 : this.my_express_edit.isUpdate
        //                 ? this.my_express_edit.shopExpressName
        //                 : this.my_express_edit.express_selected["name"];

        //             for (var x in data[0].accountList) {
        //               this.my_express_edit.account_list.push(data[0].accountList[x]);
        //             }

        //             this.my_express_edit.dataAccount_list = data[0].accountList;

        //             if (this.my_express_edit.express_selected.id == 1) {
        //               this.my_express_edit.accountID = this.my_express_edit.dataAccount_list[0].id;
        //               this.my_express_edit.costCod = this.my_express_edit.dataAccount_list[0].codCost;
        //               this.my_express_edit.CodPrice = this.my_express_edit.dataAccount_list[0].codPercent;
        //               this.my_express_edit.isCostPriceEditAble = this.my_express_edit.dataAccount_list[0].isCostPriceEditAble;
        //               this.my_express_edit.isPriceEditAble = this.my_express_edit.dataAccount_list[0].isPriceEditAble;
        //             }
        //           }
        //         );
      }
      this.loading = false;
    },
    ExpressCompanyChanged(item_selected) {
      var data = this.logistic_account_for_add.filter(
        (x) => x.expressCompanyID === item_selected.id
      );

      this.my_express_edit.account_list = [];
      this.my_express_edit.accountID = null;
      (this.my_express_edit.costCod = null), (this.my_express_edit.CodPrice = null);
      this.my_express_edit.expressValidateText =
        item_selected.id == -1 ? this.my_express_edit.expressValidateText : "";
      this.my_express_edit.showHideAdd =
        item_selected.id == -1 || item_selected.id == 1 ? false : true;
      //inter express only
      this.interExpressOption.showHide = item_selected.id == 18 ? true : false;
      //3party express showCOD
      this.my_express_edit.showCOD = ![0, 8, 9, 15].includes(item_selected.id); // ให้show COD เฉพาะขนส่งที่ไม่ได้มาจาก marketplace

      this.my_express_edit.shopExpressName =
        item_selected.id == -1
          ? null
          : this.my_express_edit.isUpdate
          ? this.my_express_edit.shopExpressName
          : this.my_express_edit.express_selected["name"];

      if (data.length > 0) {
        for (var x in data[0].accountList) {
          this.my_express_edit.account_list.push(data[0].accountList[x]);
        }
        this.my_express_edit.dataAccount_list = data[0].accountList;
      }

      if (this.my_express_edit.express_selected.id == 1) {
        this.my_express_edit.accountID = this.my_express_edit.dataAccount_list[0].id;
        this.my_express_edit.costCod = this.my_express_edit.dataAccount_list[0].codCost;
        this.my_express_edit.CodPrice = this.my_express_edit.dataAccount_list[0].codPercent;
        this.my_express_edit.isCostPriceEditAble = this.my_express_edit.dataAccount_list[0].isCostPriceEditAble;
        this.my_express_edit.isPriceEditAble = this.my_express_edit.dataAccount_list[0].isPriceEditAble;
        this.my_express_edit.hqLogisticAccountID = this.my_express_edit.dataAccount_list[0].hqLogisticAccountID;
        this.my_express_edit.branchLogisticAccountID = this.my_express_edit.dataAccount_list[0].branchLogisticAccountID;
        this.my_express_edit.shopLogisticAccountID = this.my_express_edit.dataAccount_list[0].shopLogisticAccountID;
      }
    },
    async handleUpdateBranchExpressClick() {
      if (
        this.my_express_edit.express_selected.id === -1 ||
        this.my_express_edit.express_selected.id === null
      ) {
        this.my_express_edit.rulesExpress = [(v) => (v && v.id !== -1) || "กรุณาระบุ"];
        this.$refs.refExpressAddbranchID.focus();
        this.my_express_edit.expressValidateText = "กรุณาเลือกขนส่ง";
        // this.$refs.formAddExpress.validate()
        return;
      } else {
        if (
          this.my_express_edit.accountID === 0 ||
          this.my_express_edit.accountID === null
        )
          if (![0, 8, 9, 15].includes(this.my_express_edit.express_selected.id)) {
            //  {
            //   if (
            //     this.my_express_edit.express_selected.id != 1
            //   ) {
            //     this.my_express_edit.rulesAccount = [(v) => (v && v.id !== 0) || "กรุณาระบุ"];
            //     this.$refs.refAccountValidate.focus();
            //     this.my_express_edit.accountValidateText = "กรุณาเลือก account";
            //     // this.$refs.formAddExpress.validate()
            //     return;
            //   }
            // }
            if (
              this.my_express_edit.CodPrice === null ||
              this.my_express_edit.CodPrice === ""
            ) {
              this.my_express_edit.rulesCodPersent = [(v) => !!v || "กรุณาระบุ"];
              this.$refs.refCodPersentAddbranchID.focus();
              this.my_express_edit.codPersentValidateText = "กรุณาระบุราคาขาย COD";
              // this.$refs.formAddExpress.validate()
              return;
            }
          }

        // if (
        //     this.my_express_edit.express_selected.id === 18
        // ) {
        //   await this.SetRulesInterExpress();
        //   if (
        //     this.interExpressOption.serviceCodeID === null ||
        //     this.interExpressOption.serviceCodeID === ""
        //   ) {
        //     this.$refs.formAddExpress.validate();
        //     return;
        //   } else if (
        //     this.interExpressOption.serviceControlCodeID === null ||
        //     this.interExpressOption.serviceControlCodeID === ""
        //   ) {
        //     this.$refs.formAddExpress.validate();
        //     return;
        //   } else if (
        //     this.interExpressOption.temperatureControlCodeID === null ||
        //     this.interExpressOption.temperatureControlCodeID === ""
        //   ) {
        //     this.$refs.formAddExpress.validate();
        //     return;
        //   } else if (
        //     this.interExpressOption.temperatureTypeCodeID === null ||
        //     this.interExpressOption.temperatureTypeCodeID === ""
        //   ) {
        //     this.$refs.formAddExpress.validate();
        //     return;
        //   }
        // }
      }
      this.loading = true;

      var data_request = {
        id: this.my_express_edit.isUpdate ? this.my_express.shopExpressId : 0,
        shopExpressName: this.my_express_edit.shopExpressName,
        isAllowCOD: this.my_express_edit.isCod,
        shopID: localStorage.getItem("CreateShop_ShopID"),
        branchID: localStorage.getItem("Branch_BranchID"),
        branchExpressID: this.my_express_edit.isUpdate
          ? this.my_express_edit.branchExpressID
          : 0,
        expressCompanyID: this.my_express_edit.express_selected.id,
        hqLogisticAccountID: this.my_express_edit.hqLogisticAccountID,
        branchLogisticAccountID: this.my_express_edit.branchLogisticAccountID,
        shopLogisticAccountID: this.my_express_edit.shopLogisticAccountID,
        codPercent: this.my_express_edit.CodPrice,
        codCost: this.my_express_edit.costCod,
        isDefault: this.my_express_edit.isDefault,
        // serviceCode :  this.my_express_edit.express_selected.id === 18? this.interExpressOption.serviceCodeID :null,
        // serviceControlCode:   this.my_express_edit.express_selected.id === 18 ? this.interExpressOption.serviceControlCodeID :null,
        // temperatureControlCode :   this.my_express_edit.express_selected.id === 18 ? this.interExpressOption.temperatureControlCodeID :null,
        // temperatureTypeCode :   this.my_express_edit.express_selected.id === 18 ? this.interExpressOption.temperatureTypeCodeID :null,
      };
      this.loading = false;
      this.my_express_edit.dialog = false;

      let response = await axios.post(
        logisticService_dotnet + "BranchExpress/update-shop-express",
        data_request,
        { headers: this.header_token }
      );
      if (response.data.status === "success") {
        this.loading = false;
        this.my_express_edit.dialog = false;
        this.my_express_edit.express_selected = this.my_express_edit.expressName[0];
        await this.get_branch_express();
        this.AlertSuccess();
      } else {
        this.loading = false;
        this.AlertError();
      }
    },
    async handleOpenDialogExpress(item, type) {
      this.my_express.expressLogoDialog = item.expressCompanyLogo;
      if (type === 1) {
        this.my_express.dialog = true;
        this.deliveryPrice = [];
        this.removeDeliveryPrice = [];
        await this.get_branch_express_delivery_price(item.id);
      } else if (type === 2) {
        this.packsetting.packdialog = true;
        this.packsetting.removeItem = [];
        await this.get_branch_express_postbox(item.id);
      }
    },
    async handleUpdateDeliveryPriceClick() {
      if (this.$refs.formdata.validate()) {
        this.loading = true;
        for (var o in this.deliveryPrice) {
          for (var a in this.deliveryPrice[o].items) {
            this.deliveryPrice[o].items[a].costPrice =
              this.deliveryPrice[o].items[a].costPrice === ""
                ? null
                : this.deliveryPrice[o].items[a].costPrice;
          }
        }
        let response = await axios.post(
          logisticService_dotnet + "BranchExpress/update-shop-express-delivery-price",
          {
            shopExpressID: this.my_express.shopExpressId,
            deliveryPrices: this.deliveryPrice,
            deletedIDList: this.removeDeliveryPrice,
          },
          { headers: this.header_token }
        );
        this.loading = false;
        if (response.data.status === "success") {
          this.AlertSuccess();
        } else {
          this.AlertError();
        }
      }
    },
    async handleOPPProvinceDialog(isUpdate, item) {
      // this.loading = true
      // this.rulesDescription()

      if (this.province.length === 0) {
        let province = await axios.post(
          logisticService_dotnet + "Logistic/get-province",
          {},
          { headers: this.header_token }
        );
        if (province.status === 200) {
          var data = [];
          for (var i in province.data) {
            var dataItem = province.data[i];
            dataItem["disabled"] = false;
            data.push(dataItem);
          }
          this.province = data;
          this.provinceTemp = data;
        }
      } else {
        this.province = this.provinceTemp;
      }

      this.my_express.isUpdate = false;
      this.total = 0;
      this.selectProvince = [];
      this.provinceGroup.id = 0;
      this.provinceGroup.description = null;
      this.provinceGroup.expressCompanyId = item.id;

      for (var j in this.provinceGroupItem) {
        this.selectProvince.push(this.provinceGroupItem[j].provinceId);
      }

      for (var k in this.province) {
        if (this.selectProvince.includes(this.province[k].id)) {
          this.province[k].disabled = true;
        }
      }
      if (isUpdate) {
        this.my_express.isUpdate = true;
        this.provinceGroup.id = item.provinceGroupId;
        this.provinceGroup.description = item.text;
        var selectProvince = [];
        for (var o in this.provinceGroupItem) {
          if (this.provinceGroupItem[o].provinceGroupId === item.provinceGroupId) {
            selectProvince.push(this.provinceGroupItem[o].provinceId);
          }
        }
        for (var l in this.province) {
          if (selectProvince.includes(this.province[l].id)) {
            this.province[l].disabled = false;
          }
        }

        this.total = selectProvince.length;
      }
      // this.loading = false
      this.my_express.dialogProvince = true;
    },
    async handleUpdatePackSettingClick() {
      if (this.$refs.DialogPackForm.validate()) {
        this.loading = true;
        let response = await axios.post(
          logisticService_dotnet + "BranchExpress/update-shop-express-postbox",
          {
            shopExpressID: this.my_express.shopExpressId,
            isUpdateAllToShop: this.isUpdateAllToShop,
            packingChargedType: this.packsetting.packing,
            postboxList: this.packsetting.postboxlist,
            packingStep: this.packsetting.priceAsClass,
            deletedPostboxID: this.packsetting.removeItem,
          },
          { headers: this.header_token }
        );
        this.loading = false;
        if (response.data.status === "success") {
          await this.AlertSuccess();
          setTimeout(() => {
            this.packsetting.packdialog = false;
          }, 1500);
        } else {
          await this.AlertError();
        }
      }
    },
    SetDataLogicticAccountForadd(id) {
      if (!this.my_express_edit.isUpdate) {
        var dataAccount_list = this.my_express_edit.dataAccount_list.filter(
          (x) => x.id === id
        );
        var branchExpressName = dataAccount_list[0].expressName;
        if (branchExpressName != null) {
          console.log(branchExpressName);
          this.my_express_edit.shopExpressName = branchExpressName;
        }
        this.my_express_edit.costCod = dataAccount_list[0].codCost;
        this.my_express_edit.CodPrice = dataAccount_list[0].codPercent;
        this.my_express_edit.isCostPriceEditAble =
          dataAccount_list[0].isCostPriceEditAble;
        this.my_express_edit.isPriceEditAble = dataAccount_list[0].isPriceEditAble;
        this.my_express_edit.hqLogisticAccountID =
          dataAccount_list[0].hqLogisticAccountID;
        this.my_express_edit.branchLogisticAccountID =
          dataAccount_list[0].branchLogisticAccountID;
        this.my_express_edit.shopLogisticAccountID =
          dataAccount_list[0].shopLogisticAccountID;
      }
    },
    async handleShowDialogAddAccount() {
      (this.dialog_data = {
        id: 0,
        expressCompanyID: null,
        expressCompanyList: null,
        isShippop: false,
        viP_ID: null,
        viP_Password: null,
        customerID: null,
        isOMS: true,
        isNeedCollectDeliveryPrice: false,
        isPickup: false,
      }),
        (this.my_express_edit.accountDIalog = true);
      this.my_express_edit.dialog = false;
      const responseExpress = await this.get_branch_express_list();

      //ยัดexpress เข้า dropdown
      this.dialog_data.expressCompanyID = { id: 0, name: "--ทั้งหมด--" };
      this.dialog_data.expressCompanyList = [{ id: 0, name: "--ทั้งหมด--" }];

      this.dialog_data.expressCompanyList = responseExpress.filter(
        (x) => x.isShowInPackForm == true && ![1].includes(x.id)
      );
      this.dialog_data.expressCompanyList.splice(0, 0, {
        id: 0,
        name: "--ทั้งหมด--",
      });

      this.dialog_data.expressCompanyID = this.my_express_edit.express_selected;
      this.vip_id_title = null;
      this.vip_password_title = null;
      this.customer_id_title = null;

      this.LoginEmail_or_ViP_ID_IsShow = true;
      this.Key_or_ViP_Password_IsShow = true;
      this.CustomerID_IsShow = true;

      //เก็บไว้ก่อนเผื่อใช้
      // this.dialog_data = item
      this.change_title();
    },
    async AddEditAccount() {
      if (this.dialog_data.expressCompanyID.id == 0) {
        this.$refs.expressCompanyID.focus();
        return;
      } else if (
        this.LoginEmail_or_ViP_ID_IsShow == true &&
        (this.dialog_data.viP_ID == null || this.dialog_data.viP_ID == "")
      ) {
        this.$refs.viP_ID.focus();
        return;
      } else if (
        this.Key_or_ViP_Password_IsShow == true &&
        (this.dialog_data.viP_Password == null || this.dialog_data.viP_Password == "")
      ) {
        this.$refs.viP_Password.focus();
        return;
      } else if (
        this.CustomerID_IsShow == true &&
        (this.dialog_data.customerID == null || this.dialog_data.customerID == "")
      ) {
        this.$refs.refCustomerID.focus();
        return;
      }

      var param = this.dialog_data;
      this.loading = true;
      let response = await axios.post(
        logisticService_dotnet + "Logistic/add-edit-logistic-account",
        {
          id: param.id,
          expressCompanyID: param.expressCompanyID.id,
          branchID: null,
          shopID: localStorage.getItem("CreateShop_ShopID"),
          isShippop: param.isShippop,
          viP_ID: param.viP_ID,
          viP_Password: param.viP_Password,
          customerID: param.customerID,
          isOMS: param.isOMS,
          isNeedCollectDeliveryPrice: param.isNeedCollectDeliveryPrice,
          isPickup: param.isPickup,
        },
        { headers: this.header_token }
      );
      if (response.data.status == "success") {
        if (response.data.error_description != null) {
          Swal.fire({
            icon: "warning",
            title: response.data.error_description,
            // text: response.data.error_description,
          });
          this.AlertSuccess();
          this.loading = false;
          return;
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "เกิดข้อผิดพลาด !!",
          text: response.data.error_description,
        });
        this.loading = false;
        return;
      }
      if (this.my_express_edit.isUpdate) {
        this.my_express_edit.dialog = true;
        this.my_express_edit.accountDIalog = false;
        await this.get_logistic_account_edit(this.my_express.shopExpressId);
      } else {
        this.my_express_edit.accountDIalog = false;
      }
      this.loading = false;
    },

    change_title() {
      if (
        this.dialog_data.expressCompanyID !== 0 ||
        this.dialog_data.expressCompanyID.id !== 0
      ) {
        if (
          this.dialog_data.expressCompanyID === 1 ||
          this.dialog_data.expressCompanyID.id === 1
        ) {
          this.vip_id_title = "PartnerID";
          this.vip_password_title = "PartnerKey";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 2 ||
          this.dialog_data.expressCompanyID.id === 2
        ) {
          this.vip_id_title = "เบอร์โทร EasyShip";
          this.vip_password_title = null;
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = false;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 3 ||
          this.dialog_data.expressCompanyID.id === 3
        ) {
          this.vip_id_title = null;
          this.vip_password_title = null;
          this.customer_id_title = "เบอร์โทร Wallet@Post";

          this.LoginEmail_or_ViP_ID_IsShow = false;
          this.Key_or_ViP_Password_IsShow = false;
          this.CustomerID_IsShow = true;
        } else if (
          this.dialog_data.expressCompanyID === 7 ||
          this.dialog_data.expressCompanyID.id === 7
        ) {
          this.vip_id_title = "MerchantID";
          this.vip_password_title = "Merchant Token";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 11 ||
          this.dialog_data.expressCompanyID.id === 11
        ) {
          this.vip_id_title = "ShopID";
          this.vip_password_title = "ShopName";
          this.customer_id_title = "VIP ID";

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = true;
        } else if (
          this.dialog_data.expressCompanyID === 12 ||
          this.dialog_data.expressCompanyID.id === 12
        ) {
          this.vip_id_title = "Seller ID";
          this.vip_password_title = "Seller Company Name";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 13 ||
          this.dialog_data.expressCompanyID.id === 13
        ) {
          this.vip_id_title = "SoldToAccountID";
          this.vip_password_title = "PickupAccountID";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          this.dialog_data.expressCompanyID === 17 ||
          this.dialog_data.expressCompanyID.id === 17
        ) {
          this.vip_id_title = "User ID";
          this.vip_password_title = "User Secret";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else if (
          [18, 19, 20, 21, 22, 23].includes(this.dialog_data.expressCompanyID) ||
          [18, 19, 20, 21, 22, 23].includes(this.dialog_data.expressCompanyID.id)
        ) {
          this.vip_id_title = "Public_Key ";
          this.vip_password_title = "Secret_Key";
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = false;
        } else {
          this.vip_id_title = null;
          this.vip_password_title = null;
          this.customer_id_title = null;

          this.LoginEmail_or_ViP_ID_IsShow = true;
          this.Key_or_ViP_Password_IsShow = true;
          this.CustomerID_IsShow = true;
        }
        if (this.dialog_data.id == 0) {
          this.dialog_data.viP_ID = null;
          this.dialog_data.viP_Password = null;
          this.dialog_data.customerID = null;
        }
      }
    },

    SetDeliveryPrice() {
      if (this.deliveryPrice.length != 0) {
        this.deliveryPrice.headers = [
          {
            align: "center",
            text: "น้ำหนัก",
            value: "weight",
            width: "100px",
            sortable: false,
          },
        ];
        this.deliveryPrice.headers.push({
          align: "center",
          text: "ขนาด",
          sortable: false,
          value: "ขนาด",
          width: "100px",
        });

        var columns = this.deliveryPrice[0].items;
        for (var dc in columns) {
          this.deliveryPrice["headers"].push({
            align: "center",
            text: this.provinceGroup
              .filter((x) => x.id === columns[dc]["provinceGroupID"])
              .map((i) => i.description)[0],
            provinceGroupId: columns[dc]["provinceGroupID"],
            sortable: false,
            value: this.provinceGroup
              .filter((x) => x.id === columns[dc]["provinceGroupID"])
              .map((i) => i.description)[0],
            price: [
              { text: "ราคาขายร้านค้า", value: "price" },
              { text: "ต้นทุนค่าขนส่ง", value: "costPrice" },
            ],
          });
        }
      }
    },

    CheckPackingHeader(packing) {
      var postBoxHeader = [];
      if (packing === 2) {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "200px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค + ค่าบรรจุภัณฑ์",
            value: "packAndBoxPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "90px",
          },
        ];
      } else {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "180px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาขายกล่อง",
            value: "boxSellPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค",
            value: "packPrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "100px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "90px",
          },
        ];
      }
      return postBoxHeader;
    },
    AddPostBox(pName) {
      pName.push({
        // key: pName.length == 0 ? 0 : pName[pName.length - 1]["key"] + 1,
        id: 0,
        name: "",
        cubic1: null,
        cubic2: null,
        cubic3: null,
        branchExpressID: 0,
        shopExpressID: 0,
        boxCostPrice: null,
        boxSellPrice: null,
        packPrice: null,
        packAndBoxPrice: null,
        bubblePrice: null,
        expressCompanyID: 0,
        orderNo: pName.length + 1,
        branchId: 0,
        shopId: localStorage.getItem("CreateShop_ShopID"),
        parentID: 0,
      });
    },
    RemovePostBox(pName, index) {
      Swal.fire({
        icon: "warning",
        title: "ลบ ใช่ หรือ ไม่",
        text: "ต้องการลบ " + pName[index]["name"] + " ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.packsetting.removeItem.push(
            pName.filter((_, idex) => idex === index).map((x) => x.id)[0]
          );
          console.log(this.packsetting.removeItem);
          for (var i in pName) {
            if (parseInt(i) >= index) {
              pName[i]["orderNo"] = parseInt(pName[i]["orderNo"]) - 1;
            }
          }
          pName.splice(index, 1);
        }
      });
    },
    AddDeliveryPrice(dName) {
      var sizeaction = dName.filter((x) => x.size !== null);
      dName.push({
        no: dName.length + 1,
        size: sizeaction.length <= 0 ? null : dName[dName.length - 1]["size"] + 1,
        weight: dName[dName.length - 1]["weight"] + 1,
        items: [
          {
            id: 0,
            costPrice: null,
            parentID: null,
            price: null,
            provinceGroupID: this.provinceGroup
              .filter((x) => x.description === "กรุงเทพฯ")
              .map((i) => i.id)[0],
            publicPrice: null,
          },
          {
            id: 0,
            costPrice: null,
            parentID: null,
            price: null,
            provinceGroupID: this.provinceGroup
              .filter((x) => x.description === "ต่างจังหวัด")
              .map((i) => i.id)[0],
            publicPrice: null,
          },
        ],
      });
    },
    RemoveDeliveryPrice(dName, index) {
      Swal.fire({
        icon: "warning",
        title: "ลบ ใช่ หรือ ไม่",
        text: "ต้องการลบน้ำหนัก " + dName[index]["weight"] + " ใช่ หรือ ไม่",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var results = [dName[index].items[0].id, dName[index].items[1].id];
          for (var i in results) {
            this.removeDeliveryPrice.push(results[i]);
          }
          dName.splice(index, 1);
        }
      });
    },
    SetOrderNo(pName, index) {
      if (
        parseInt(pName[index]["orderNo"]) > 0 &&
        parseInt(pName[index]["orderNo"]) <= pName.length
      ) {
        var num = [index, index];

        // หาตำแหน่งที่จะไปแทนที่
        for (var i in pName) {
          if (
            parseInt(index) != parseInt(i) &&
            parseInt(pName[index]["orderNo"]) == pName[i]["orderNo"]
          ) {
            num[1] = parseInt(i);
            break;
          }
        }
        if (num[0] != num[1]) {
          // ลบตำแหน่งตัวเอง
          var list = pName[index];
          pName.splice(index, 1);

          // เพิ่มตำแหน่งที่จะไป
          pName.splice(num[1], 0, list);

          // เรียง orderNo ใหม่
          for (var t in pName) {
            pName[t]["orderNo"] = parseInt(t) + 1;
          }
        }
      }
    },
    SetRulesInterExpress() {
      this.interExpressOption.rulesServiceCode = [(v) => Trim_rules(v) || "กรุณาระบุ"];
      this.interExpressOption.rulesServiceControlCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
      this.interExpressOption.rulesTemperatureControlCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
      this.interExpressOption.rulesTemperatureTypeCode = [
        (v) => Trim_rules(v) || "กรุณาระบุ",
      ];
    },
    ClearRules() {
      this.my_express_edit.rulesAccount = [];
      this.my_express_edit.rulesCodPersent = [];
      this.my_express_edit.rulesExpress = [];
      this.my_express_edit.expressValidateText = "";
      this.my_express_edit.accountValidateText = "";
      this.my_express_edit.codPersentValidateText = "";
      this.interExpressOption.rulesServiceCode = [];
      this.interExpressOption.rulesServiceControlCode = [];
      this.interExpressOption.rulesTemperatureControlCode = [];
      this.interExpressOption.rulesTemperatureTypeCode = [];
    },
    ///
    // เพิ่มคลัง
    async GetWarehouse() {
      let warehouseResponse = await axios.get(
        branchService_dotnet +
          "Branch/get-warehouse-list?BranchID=" +
          localStorage.getItem("Branch_BranchID"),
        { headers: this.header_token }
      );
      if (warehouseResponse.status == 200 && warehouseResponse.data.status == "success") {
        this.warehouseAccess.datawarehouse = warehouseResponse.data.warehouseList;
        for (var i in this.warehouseAccess.datawarehouse) {
          if (this.warehouseAccess.datawarehouse[i].postcodemappingID != null) {
            this.warehouseAccess.datawarehouse[i].fullAddress =
              (this.warehouseAccess.datawarehouse[i].address == null
                ? ""
                : this.warehouseAccess.datawarehouse[i].address + " ") +
              this.warehouseAccess.datawarehouse[i].subDistrict +
              " " +
              this.warehouseAccess.datawarehouse[i].district +
              " " +
              this.warehouseAccess.datawarehouse[i].province +
              " " +
              this.warehouseAccess.datawarehouse[i].postcode;
          }
        }
        const datalist = await this.GetWarehouseAccessbyShopID();
        this.warehouseAccess.selectWarehouse = [];
        this.warehouseAccess.datawarehouse = this.warehouseAccess.datawarehouse.map(
          (item) => ({
            ...item,
            IsAllowAddOrderWhenStockShortage:
              datalist.find((j) => j.warehouseId === item.id)
                ?.isAllowAddOrderWhenStockShortage ?? false,
            accessID: datalist.find((j) => j.warehouseId === item.id)?.id ?? 0,
          })
        );
        this.warehouseAccess.datawarehouse.forEach((item) => {
          if (datalist.some((x) => x.warehouseId === item.id)) {
            this.warehouseAccess.selectWarehouse.unshift(item);
          }
        });
      }
    },
    async GetWarehouseAccessbyShopID() {
      let response = await axios.get(
        branchService_dotnet +
          "Branch/get-warehouseAccess-by-shopID?ShopID=" +
          parseInt(localStorage.getItem("CreateShop_ShopID")),
        { headers: this.header_token }
      );
      if (response.data.status) {
        return response.data.warehouseAccessList;
      }
    },
    async HandleUpdateWarehouseAccess() {
      const dataSet = this.warehouseAccess.selectWarehouse.map((x) => ({
        isAllowAddOrderWhenStockShortage: x.IsAllowAddOrderWhenStockShortage,
        shopId: parseInt(localStorage.getItem("CreateShop_ShopID")),
        warehouseId: x.id,
        id: x.accessID,
      }));
      let response = await axios.post(
        branchService_dotnet + "Branch/update-warehouseAccess-by-shopID",
        {
          warehouseAccessList: dataSet,
          shopID: parseInt(localStorage.getItem("CreateShop_ShopID")),
        },
        { headers: this.header_token }
      );
      if (!response.data.status) {
        this.$swal({
          type: "warning",
          text: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
        });
      }
    },
    // setShopEdit
    setShop(shop) {
      this.data.shop.name = shop.name;
      this.data.shop.email = shop.email;
      this.data.shop.minimumCredit = shop.balanceLimit == null ? 0 : 1;
      this.data.shop.balanceLimit = parseFloat(
        shop.balanceLimit == null ? 0 : shop.balanceLimit
      ).toFixed(2);
      this.data.shop.isAllowAddOrderWhenStockBranchNotAvailable = shop.isAllowAddOrderWhenStockBranchNotAvailable
        ? 1
        : 0;
      this.data.shop.autoInvoiceType = shop.autoInvoiceType;
      this.data.shop.serviceRateVatType = shop.serviceRateVatType;
      this.data.shop.addressPrintType = shop.addressPrintType;
      this.data.shop.vrichTransactionFee = shop.vrichTransactionFee;
      this.data.shop.rackPrice = shop.rackPrice;
      this.data.shop.shelfPrice = shop.shelfPrice;
      this.data.shop.palletePrice = shop.palletePrice;
      this.data.shop.coolRackPrice = shop.coolRackPrice;
      this.data.shop.coolShelfPrice = shop.coolShelfPrice;
      this.data.shop.coolPalletePrice = shop.coolPalletePrice;
      this.data.shop.cutOffTimeHour =
        shop.cutOffTime == null ? { data: "12" } : { data: shop.cutOffTime.substr(0, 2) };
      this.data.shop.cutOffTimeMinute =
        shop.cutOffTime == null ? { data: "00" } : { data: shop.cutOffTime.substr(3, 5) };
      this.data.shop.autoCalculateSpaceDateSelect = {
        day:
          shop.autoCalculateSpaceDate == 0 || shop.autoCalculateSpaceDate == 31
            ? 1
            : shop.autoCalculateSpaceDate,
      };
      this.data.shop.autoCalculateSpaceDateTemp =
        shop.autoCalculateSpaceDate == 0 ? 2 : shop.autoCalculateSpaceDate == 31 ? 0 : 1;
      this.data.shop.additionalCondition = shop.additionalCondition;
      this.data.shop.isActive = shop.isActive ? 1 : 0;

      this.data.shop.isDelete = shop.isDelete;
      this.data.shop.addOrderMode = shop.addOrderMode;
      this.data.shop.isHideAddress = shop.isHideAddress;
      this.data.shop.defaultExpressCompanyID = shop.defaultExpressCompanyID;
      this.data.shop.isNeedImportFile = shop.isNeedImportFile;
      this.data.shop.customerType = shop.customerType;
      this.data.shop.balance = shop.balance;
      this.data.shop.codWallet = shop.codWallet;
      this.data.shop.agentBalance = shop.agentBalance;
      this.data.shop.isUsingAllocateStock = shop.isUsingAllocateStock;
    },
    setShopAddress(shopAddress) {
      this.data.shopAddress.id = shopAddress.id;
      this.data.shopAddress.shopId = shopAddress.shopId;
      this.data.shopAddress.name = shopAddress.name;
      this.data.shopAddress.phone = shopAddress.phone;
      this.data.shopAddress.address = shopAddress.address;
      for (var i in this.postcode_mapping) {
        if (this.postcode_mapping[i]["Id"] == shopAddress.postcodeMappingId) {
          this.data.shopAddress.postcodeMapping = this.postcode_mapping[i];
          break;
        }
      }
    },
    setStaffShop(staffShop) {
      this.data.staffShop.id = staffShop.id;
      this.data.staffShop.shopId = staffShop.shopId;
      this.data.staffShop.name = staffShop.name;
      this.data.staffShop.phone = staffShop.phone;
      this.data.staffShop.username = staffShop.username;
      this.data.staffShop.password = staffShop.password;
    },
    setShopAddressInvoice(shopAddressInvoice) {
      if (shopAddressInvoice != "") {
        this.data.shopAddressInvoice.id = shopAddressInvoice.id;
        this.data.shopAddressInvoice.shopId = shopAddressInvoice.shopId;
        this.data.shopAddressInvoice.name = shopAddressInvoice.name;
        this.data.shopAddressInvoice.phone = shopAddressInvoice.phone;
        this.data.shopAddressInvoice.address = shopAddressInvoice.address;
        this.data.shopAddressInvoice.idCardNumber = shopAddressInvoice.idcardNumber;
        for (var i in this.postcode_mapping) {
          if (this.postcode_mapping[i]["Id"] == shopAddressInvoice.postcodeMappingId) {
            this.data.shopAddressInvoice.postcodeMapping = this.postcode_mapping[i];
            break;
          }
        }
      }
    },
    setShopMonthlyfee(shopMonthlyfee) {
      for (var i in shopMonthlyfee) {
        // if (shopMonthlyfee[i]["createdDatetime"]!=null){
        //   shopMonthlyfee[i]["createdDatetime"] = this.formatDatetime(shopMonthlyfee[i]["createdDatetime"])
        // }
        if (shopMonthlyfee[i]["startDate"] != null) {
          shopMonthlyfee[i]["startDate"] = this.formatDate_eng(
            shopMonthlyfee[i]["startDate"]
          );
        }
        if (shopMonthlyfee[i]["endDate"] != null) {
          shopMonthlyfee[i]["endDate"] = this.formatDate_eng(
            shopMonthlyfee[i]["endDate"]
          );
        }
      }
      this.data.shopMonthlyFee.dataset = shopMonthlyfee;
    },

    // shopMonthlyFee
    async UpdateShopMonthlyFee(index, pUpdate, item) {
      await this.rulesShopMonthlyFee();
      if (!pUpdate) {
        this.data.shopMonthlyFee.id = 0;
        this.data.shopMonthlyFee.serviceCharge = null;
        this.data.shopMonthlyFee.price = null;
        this.data.shopMonthlyFee.startDate = null;
        this.data.shopMonthlyFee.endDate = null;
        this.data.shopMonthlyFee.selectEndDate = 0;
        this.data.shopMonthlyFee.createInvoiceEveryDay = this.data.shopMonthlyFee.createInvoiceEveryDay_List[0];
        this.startDate = new Date().toISOString().substr(0, 10);
        this.dateStart = this.formatDate(this.startDate);
        this.endDate = null;
        this.dateEnd = null;
      } else {
        this.data.shopMonthlyFee.id = item.id;
        this.data.shopMonthlyFee.serviceCharge = item.serviceCharge;
        this.data.shopMonthlyFee.price = item.price;
        this.data.shopMonthlyFee.startDate = item.startDate;
        this.data.shopMonthlyFee.endDate = item.endDate;
        this.data.shopMonthlyFee.selectEndDate = 0;
        if (this.data.shopMonthlyFee.endDate != null) {
          this.data.shopMonthlyFee.selectEndDate = 1;
        }
        this.data.shopMonthlyFee.createInvoiceEveryDay = this.data.shopMonthlyFee.createInvoiceEveryDay_List[
          item.createInvoiceEveryDay - 1
        ];
        this.startDate = item.startDate;
        this.dateStart = this.formatDate(this.startDate);
        this.endDate = item.endDate;
        if (this.endDate != null) {
          this.dateEnd = this.formatDate(this.endDate);
        }
      }
      this.data.shopMonthlyFee.index = index;
      this.data.shopMonthlyFee.pUpdate = pUpdate;
      this.data.shopMonthlyFee.dialog = true;
    },
    rulesShopMonthlyFee() {
      this.data.shopMonthlyFee.rulesServiceCharge = [];
      this.data.shopMonthlyFee.rulesPrice = [];
      this.data.shopMonthlyFee.rulesStartDate = [];
      this.data.shopMonthlyFee.rulesEndDate = [];
      this.data.shopMonthlyFee.rulesCreateInvoiceEveryDay = [];
    },
    rulesShopMonthlyFeeCheck() {
      this.data.shopMonthlyFee.rulesServiceCharge = [(v) => !!v || "กรุณาระบุ"];
      this.data.shopMonthlyFee.rulesPrice = [(v) => !!v || "กรุณาระบุ"];
      this.data.shopMonthlyFee.rulesStartDate = [(v) => !!v || "กรุณาระบุ"];
      if (this.data.shopMonthlyFee.selectEndDate == 1) {
        this.data.shopMonthlyFee.rulesEndDate = [(v) => !!v || "กรุณาระบุ"];
      }
      this.data.shopMonthlyFee.rulesCreateInvoiceEveryDay = [(v) => !!v || "กรุณาระบุ"];
    },
    async SubmitShopMonthlyFee() {
      await this.rulesShopMonthlyFeeCheck();
      if (this.$refs.dialogShopMonthlyFeeForm.validate()) {
        this.data.shopMonthlyFee.startDate = this.startDate;
        this.data.shopMonthlyFee.endDate = this.endDate;
        if (!this.data.shopMonthlyFee.pUpdate) {
          this.data.shopMonthlyFee.dataset.push({
            key:
              this.data.shopMonthlyFee.dataset.length == 0
                ? 0
                : this.data.shopMonthlyFee.dataset[
                    this.data.shopMonthlyFee.dataset.length - 1
                  ]["key"] + 1,
            id: 0,
            shopId: 0,
            createdDatetime: new Date(),
            createdBy: localStorage.getItem("Branch_StaffID"),
            serviceCharge: this.data.shopMonthlyFee.serviceCharge,
            startDate: this.data.shopMonthlyFee.startDate,
            endDate: this.data.shopMonthlyFee.endDate,
            createInvoiceEveryDay: this.data.shopMonthlyFee.createInvoiceEveryDay.day,
            price: this.data.shopMonthlyFee.price,
          });
        } else {
          for (var i in this.data.shopMonthlyFee.dataset) {
            if (i == this.data.shopMonthlyFee.index) {
              this.data.shopMonthlyFee.dataset[i][
                "serviceCharge"
              ] = this.data.shopMonthlyFee.serviceCharge;
              this.data.shopMonthlyFee.dataset[i][
                "startDate"
              ] = this.data.shopMonthlyFee.startDate;
              this.data.shopMonthlyFee.dataset[i]["endDate"] =
                this.data.shopMonthlyFee.selectEndDate == 1
                  ? this.data.shopMonthlyFee.endDate
                  : null;
              this.data.shopMonthlyFee.dataset[i][
                "createInvoiceEveryDay"
              ] = this.data.shopMonthlyFee.createInvoiceEveryDay.day;
              this.data.shopMonthlyFee.dataset[i][
                "price"
              ] = this.data.shopMonthlyFee.price;
            }
          }
        }
        this.data.shopMonthlyFee.dialog = false;
      }
    },
    async DeleteShopMonthlyFee(item, index) {
      Swal.fire({
        icon: "warning",
        title: "ต้องการลบ ใช่ หรือ ไม่",
        text: item.serviceCharge,
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          for (var i in this.data.shopMonthlyFee.dataset) {
            if (i == index) {
              this.data.shopMonthlyFee.dataset.splice(i, 1);
            }
          }
          this.AlertSuccess();
        }
      });
    },

    SelectedExpress(expressList, branchExpressList) {
      var expressListTemp = [];
      for (var t in branchExpressList) {
        for (var r in expressList) {
          if (expressList[r]["id"] == branchExpressList[t]["expressCompanyId"]) {
            expressListTemp.push(expressList[r]);
            break;
          }
        }
      }

      var express = [];
      for (var a in expressListTemp) {
        if (
          expressListTemp[a]["id"] == 0 ||
          expressListTemp[a]["id"] == 8 ||
          expressListTemp[a]["id"] == 9 ||
          expressListTemp[a]["id"] == 15 ||
          expressListTemp[a]["id"] == 16
        ) {
          express.push(expressListTemp[a]);
        }
      }
      for (var i in expressListTemp) {
        if (
          expressListTemp[i]["id"] != 0 &&
          expressListTemp[i]["id"] != 8 &&
          expressListTemp[i]["id"] != 9 &&
          expressListTemp[i]["id"] != 15 &&
          expressListTemp[i]["id"] != 16
        ) {
          express.push(expressListTemp[i]);
        }
      }
      this.data.express.express_List = express;
      this.CheckExpress();
    },
    CheckExpress() {
      if (this.data.express.selectExpress.length > 0) {
        this.data.express.tabExpress_List = [];
        var number = [];
        for (var x = 0; x < this.data.express.selectExpress.length; x++) {
          number.push(this.data.express.selectExpress[x]);
        }
        number.sort();
        for (var y = 0; y < this.data.express.express_List.length; y++) {
          if (number.includes(this.data.express.express_List[y]["id"])) {
            this.data.express.tabExpress_List.push({
              id: this.data.express.express_List[y]["id"],
              name: this.data.express.express_List[y]["name"],
            });
          }
        }
      }
    },

    ChangeFollowPices(pName, index) {
      if (pName[index]["end"] == "") {
        pName.splice(index + 1);
        return;
      } else if (pName.length - 1 == index) {
        if (parseInt(pName[index]["end"]) < parseInt(pName[index]["start"])) {
          pName[index]["end"] = "";
          return;
        }
        pName.push({
          id: 0,
          start: parseInt(pName[index]["end"]) + 1,
          end: "",
          price: 0,
          no: parseInt(pName[index]["no"]) + 1,
        });
      } else if (
        parseInt(pName[index]["end"]) < parseInt(pName[index]["start"]) ||
        (pName[index + 1]["end"] != "" &&
          parseInt(pName[index]["end"]) > parseInt(pName[index + 1]["end"]) - 1)
      ) {
        pName[index]["end"] = parseInt(pName[index + 1]["start"]) - 1;
      } else {
        pName[index + 1]["start"] = parseInt(pName[index]["end"]) + 1;
      }
    },
    CheckPackingReturn(packing) {
      var postBoxHeader = [];
      if (packing == 2) {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "250px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค + ค่าบรรจุภัณฑ์",
            value: "packAndBoxPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "100px",
          },
        ];
      } else {
        postBoxHeader = [
          {
            align: "center",
            text: "ลำดับที่",
            value: "orderNo",
            sortable: false,
            width: "100px",
          },
          {
            align: "left",
            text: "กล่อง",
            value: "name",
            sortable: false,
            width: "250px",
          },
          {
            align: "center",
            text: "กว้าง ",
            value: "cubic1",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ยาว",
            value: "cubic2",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "สูง",
            value: "cubic3",
            sortable: false,
            width: "120px",
          },
          {
            align: "center",
            text: "ต้นทุนกล่อง",
            value: "boxCostPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาขายกล่อง",
            value: "boxSellPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาค่าแพ็ค",
            value: "packPrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ราคาBubble",
            value: "bubblePrice",
            sortable: false,
            width: "130px",
          },
          {
            align: "center",
            text: "ลบ",
            value: "delete",
            sortable: false,
            width: "100px",
          },
        ];
      }
      return postBoxHeader;
    },

    // เพิ่ม แก้ไข shop
    async ShopUpdate() {
      await this.CheckFocus();
      if (this.data.shop.id > 0) {
        await this.HandleUpdateWarehouseAccess();
      }

      // express // จัดการลำดับที่ จึงต้องเอา for มาไว้ตรงนี้
      var express_List = [];
      for (var e in this.data.express.express_List) {
        for (var s in this.data.express.selectExpress) {
          if (
            this.data.express.selectExpress[s] == this.data.express.express_List[e]["id"]
          ) {
            // จัดการ priceAsClass
            if (
              this.data.express.express_List[e].dataset.servicePacking.packing == 3 ||
              this.data.express.express_List[e].dataset.servicePacking.packing == 4
            ) {
              for (var j in this.data.express.express_List[e].dataset.servicePacking
                .priceAsClass) {
                if (
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[j]
                    .no != j
                ) {
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                    j
                  ].id = parseInt(j);
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                    j
                  ].no = parseInt(j);
                } else {
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                    j
                  ].id = this.data.express.express_List[
                    e
                  ].dataset.servicePacking.priceAsClass[j].no;
                }
                this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                  j
                ].start = parseInt(
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[j]
                    .start
                );
                var end = null;
                if (
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[j]
                    .end == ""
                ) {
                  end = null;
                } else {
                  end = this.data.express.express_List[e].dataset.servicePacking
                    .priceAsClass[j].end;
                  if (end != null) {
                    end = end.toString();
                  }
                }
                this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                  j
                ].end = end;
                this.data.express.express_List[e].dataset.servicePacking.priceAsClass[
                  j
                ].price = parseFloat(
                  this.data.express.express_List[e].dataset.servicePacking.priceAsClass[j]
                    .price
                ).toFixed(2);
              }
            } else {
              this.data.express.express_List[e].dataset.servicePacking.priceAsClass = [];
            }

            // จัดการ ลำดับที่
            for (var b in this.data.express.express_List[e].dataset.servicePacking
              .priceAsBox.dataset) {
              this.data.express.express_List[e].dataset.servicePacking.priceAsBox.dataset[
                b
              ]["orderNo"] = parseInt(b) + 1;
            }

            express_List.push(this.data.express.express_List[e]);
          }
        }
      }

      if (this.$refs.formAddShop.validate()) {
        this.loading = true;
        // shop
        var shop = {
          branchId: parseInt(localStorage.getItem("Branch_BranchID")),
          id: this.data.shop.id,
          name: this.data.shop.name,
          email: this.data.shop.email,
          createdDatetime: this.data.shop.createdDatetime,
          isIgnoreDimension:this.data.shop.isIgnoreDimension,
          isIgnoreReceiverAdress:this.data.shop.isIgnoreReceiverAdress,
          isIgnoreWeight:this.data.shop.isIgnoreWeight,
          // เงินเครดิตขั้นต่ำ
          minimumCredit: this.data.shop.minimumCredit,
          balanceLimit:
            this.data.shop.minimumCredit == 0 ? null : this.data.shop.balanceLimit,
          // สร้างออเดอร์
          isAllowAddOrderWhenStockBranchNotAvailable: this.data.shop
            .isAllowAddOrderWhenStockBranchNotAvailable,
          // การสร้างใบแจ้งหนี้
          autoInvoiceType: this.data.shop.autoInvoiceType,
          // ประเภทค่าบริการ
          serviceRateVatType: this.data.shop.serviceRateVatType,
          // ใบปะหน้า
          addressPrintType: this.data.shop.addressPrintType,
          // ค่าบริการเชื่อมต่อ VRich
          vrichTransactionFee:
            this.data.shop.vrichTransactionFee == ""
              ? null
              : this.data.shop.vrichTransactionFee,
          // ค่าบริการฝากสินค้า
          rackPrice:
            this.data.shop.rackPrice == "" || this.data.shop.rackPrice == null
              ? null
              : parseFloat(this.data.shop.rackPrice).toFixed(2),
          shelfPrice:
            this.data.shop.shelfPrice == "" || this.data.shop.shelfPrice == null
              ? null
              : parseFloat(this.data.shop.shelfPrice).toFixed(2),
          palletePrice:
            this.data.shop.palletePrice == "" || this.data.shop.palletePrice == null
              ? null
              : parseFloat(this.data.shop.palletePrice).toFixed(2),
          coolRackPrice:
            this.data.shop.coolRackPrice == "" || this.data.shop.coolRackPrice == null
              ? null
              : parseFloat(this.data.shop.coolRackPrice).toFixed(2),
          coolShelfPrice:
            this.data.shop.coolShelfPrice == "" || this.data.shop.coolShelfPrice == null
              ? null
              : parseFloat(this.data.shop.coolShelfPrice).toFixed(2),
          coolPalletePrice:
            this.data.shop.coolPalletePrice == "" ||
            this.data.shop.coolPalletePrice == null
              ? null
              : parseFloat(this.data.shop.coolPalletePrice).toFixed(2),
          // เวลาตัดรอบ
          cutOffTime:
            this.data.shop.cutOffTimeHour.data +
            ":" +
            this.data.shop.cutOffTimeMinute.data,
          // ค่าเช่าพื้นที
          autoCalculateSpaceDate:
            this.data.shop.autoCalculateSpaceDateTemp == 0
              ? 31
              : this.data.shop.autoCalculateSpaceDateTemp == 2
              ? 0
              : this.data.shop.autoCalculateSpaceDateSelect.day,
          // เงื่อนไขเพิ่มเติม
          additionalCondition: this.data.shop.additionalCondition,
          // ประเภทลูกค้า
          isActive: this.data.shop.isActive,

          isDelete: this.data.shop.isDelete,
          addOrderMode: this.data.shop.addOrderMode,
          isHideAddress: this.data.shop.isHideAddress,
          defaultExpressCompanyID: this.data.shop.defaultExpressCompanyID,
          isNeedImportFile: this.data.shop.isNeedImportFile,
          customerType: this.data.shop.customerType,
          balance: this.data.shop.balance,
          codWallet: this.data.shop.codWallet,
          agentBalance: this.data.shop.agentBalance,
          isUsingAllocateStock: this.data.shop.isUsingAllocateStock,
        };
        shop.isAllowAddOrderWhenStockBranchNotAvailable = shop.isAllowAddOrderWhenStockBranchNotAvailable
          ? true
          : false;
        shop.isActive = shop.isActive ? true : false;

        // shopAddress
        this.data.shopAddress.name = this.data.shop.name;
        this.data.shopAddress.postcodeMappingId = this.data.shopAddress.postcodeMapping.Id;
        this.data.shopAddress.tumbon = this.data.shopAddress.postcodeMapping.SubDistrict;
        this.data.shopAddress.amphoe = this.data.shopAddress.postcodeMapping.District;
        this.data.shopAddress.province = this.data.shopAddress.postcodeMapping.Province;
        this.data.shopAddress.postcode = this.data.shopAddress.postcodeMapping.Postcode;

        // shopAddressInvoice
        var shopAddressInvoice = {};
        if (this.data.shopAddressInvoice.postcodeMapping != null) {
          this.data.shopAddressInvoice.postcodeMappingId = this.data.shopAddressInvoice.postcodeMapping.Id;
          shopAddressInvoice = this.data.shopAddressInvoice;
        } else {
          shopAddressInvoice = null;
        }

        // shopMonthlyFee
        var shopMonthlyFee = [];
        var dataset = this.data.shopMonthlyFee.dataset;
        for (var i in dataset) {
          shopMonthlyFee.push({
            id: parseInt(dataset[i]["id"]),
            shopId: parseInt(dataset[i]["shopId"]),
            createdDatetime: dataset[i]["createdDatetime"],
            createdBy: parseInt(dataset[i]["createdBy"]),
            serviceCharge: dataset[i]["serviceCharge"],
            startDate: new Date(dataset[i]["startDate"] + " 00:00:00").toISOString(),
            endDate:
              dataset[i]["endDate"] != null
                ? new Date(dataset[i]["endDate"] + " 00:00:00").toISOString()
                : null,
            createInvoiceEveryDay:
              dataset[i]["createInvoiceEveryDay"] == null
                ? null
                : dataset[i]["createInvoiceEveryDay"].toString(),
            price: parseInt(dataset[i]["price"]),
          });
        }

        let response = await axios.post(
          shopService_dotnet + "Shop/insert-shop",
          {
            shop: shop,
            shopAddress: this.data.shopAddress,
            staffShop: this.data.staffShop,
            shopAddressInvoice: shopAddressInvoice,
            shopMonthlyFee: shopMonthlyFee,
            express: express_List,
            branchId: parseInt(localStorage.getItem("Branch_BranchID")),
            staffId: parseInt(localStorage.getItem("Branch_StaffID")),
          },
          { headers: this.header_token }
        );
        this.loading = false;
        if (response.data.status == "success") {
          this.AlertSuccess();
          this.toBack("manageshop");
        } else {
          this.AlertWarning(response.data.message.th);
          this.$vuetify.goTo(this.$refs.datastaffShopusername, { offset: 50 });
          this.$refs.datastaffShopusername.focus();
        }
      }
    },
    async CheckFocus() {
      // ร้านค้า
      if (this.data.shop.name == "" || this.data.shop.name == null) {
        this.$vuetify.goTo(this.$refs.datashopname, { offset: 50 });
        this.$refs.datashopname.focus();
        return;
      }
      if (this.data.shopAddress.phone == "" || this.data.shopAddress.phone == null) {
        this.$vuetify.goTo(this.$refs.datashopAddressphone, { offset: 50 });
        this.$refs.datashopAddressphone.focus();
        return;
      }
      if (this.data.shopAddress.address == "" || this.data.shopAddress.address == null) {
        this.$vuetify.goTo(this.$refs.datashopAddressaddress, { offset: 50 });
        this.$refs.datashopAddressaddress.focus();
        return;
      }
      if (this.data.shop.email == "" || this.data.shop.email == null) {
        this.$vuetify.goTo(this.$refs.datashopemail, { offset: 50 });
        this.$refs.datashopemail.focus();
        return;
      }
      if (
        this.data.shopAddress.postcodeMapping == "" ||
        this.data.shopAddress.postcodeMapping == null
      ) {
        this.$vuetify.goTo(this.$refs.datashopAddresspostcodeMapping, { offset: 50 });
        this.$refs.datashopAddresspostcodeMapping.focus();
        return;
      }

      // ผู้ใช้งานเริ่มต้น
      if (this.data.staffShop.name == "" || this.data.staffShop.name == null) {
        this.$vuetify.goTo(this.$refs.datastaffShopname, { offset: 50 });
        this.$refs.datastaffShopname.focus();
        return;
      }
      if (this.data.staffShop.phone == "" || this.data.staffShop.phone == null) {
        this.$vuetify.goTo(this.$refs.datastaffShopphone, { offset: 50 });
        this.$refs.datastaffShopphone.focus();
        return;
      }
      if (this.data.staffShop.username == "" || this.data.staffShop.username == null) {
        this.$vuetify.goTo(this.$refs.datastaffShopusername, { offset: 50 });
        this.$refs.datastaffShopusername.focus();
        return;
      }
      if (this.data.staffShop.password == "" || this.data.staffShop.password == null) {
        this.$vuetify.goTo(this.$refs.datastaffShoppassword, { offset: 50 });
        this.$refs.datastaffShoppassword.focus();
        return;
      }

      // ตั้งค่าร้านค้า
      if (
        this.data.shop.minimumCredit == 1 &&
        (this.data.shop.balanceLimit == "" || this.data.shop.balanceLimit == null)
      ) {
        this.$vuetify.goTo(this.$refs.datashopbalanceLimit, { offset: 50 });
        this.$refs.datashopbalanceLimit.focus();
        return;
      }

      // เลือกขนส่ง
      var expressList = this.data.express.express_List;
      for (var ii in this.data.express.selectExpress) {
        for (var i in expressList) {
          if (this.data.express.selectExpress[ii] == expressList[i].id) {
            var dataset = expressList[i]["dataset"];

            if (
              this.data.express.selectExpress[ii] != 8 &&
              this.data.express.selectExpress[ii] != 9
            ) {
              // ตั้งค่าทั่วไป
              if (
                dataset["setting"]["CODPercent"] == "" ||
                dataset["setting"]["CODPercent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }
              if (
                dataset["setting"]["Remote_Area_Discount_Percent"] == "" ||
                dataset["setting"]["Remote_Area_Discount_Percent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }

              // ตั้งค่าส่วนลด ในกรณีสินค้าตีกลับ
              if (
                dataset["discount"]["returnDeliveryDiscountPercent"] == "" ||
                dataset["discount"]["returnDeliveryDiscountPercent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }
              if (
                dataset["discount"]["returnCODDiscountPercent"] == "" ||
                dataset["discount"]["returnCODDiscountPercent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }
              if (
                dataset["discount"]["returnBoxPriceDiscountPercent"] == "" ||
                dataset["discount"]["returnBoxPriceDiscountPercent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }
              if (
                dataset["discount"]["returnPackPriceDiscountPercent"] == "" ||
                dataset["discount"]["returnPackPriceDiscountPercent"] == null
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }

              // ตั้งค่าค่าขนส่ง
            }

            // ตั้งค่าค่าบริการแพ็คสินค้า ตามขนาดกล่อง
            // if (dataset["servicePacking"]["packing"] == 1){

            for (var pb in dataset["servicePacking"]["priceAsBox"]["dataset"]) {
              if (
                dataset["servicePacking"]["priceAsBox"]["dataset"][pb]["orderNo"] ==
                  null ||
                dataset["servicePacking"]["priceAsBox"]["dataset"][pb][
                  "orderNo"
                ].toString() == ""
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              } else if (
                dataset["servicePacking"]["priceAsBox"]["dataset"][pb]["name"] == null ||
                dataset["servicePacking"]["priceAsBox"]["dataset"][pb][
                  "name"
                ].toString() == ""
              ) {
                this.data.express.tabExpress = await this.CheckTab(
                  this.data.express.selectExpress[ii]
                );
                return;
              }
            }

            // ตั้งค่าค่าบริการแพ็คสินค้า ตามจำนวนชิ้น
            if (dataset["servicePacking"]["packing"] == 3) {
              for (var pc in dataset["servicePacking"]["priceAsClass"]) {
                var price =
                  dataset["servicePacking"]["priceAsClass"][pc]["price"] != null
                    ? dataset["servicePacking"]["priceAsClass"][pc]["price"].toString()
                    : null;
                if (price == "" || price == null) {
                  this.data.express.tabExpress = await this.CheckTab(
                    this.data.express.selectExpress[ii]
                  );
                  return;
                }
              }
            }
          }
        }
      }
    },
    // อัพเดทสต๊อก
    async handleUpdateStockClick() {
      let response = await axios.post(
        ecomService_dotnet + "Stock/update-to-marketplace-with-shopid",
        {
          ShopID: parseInt(localStorage.getItem("CreateShop_ShopID")),
        },
        { headers: this.header_token }
      );
      if (response.status === 200) {
        this.$swal({
          toast: true,
          timer: 2300,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "อัพเดทสต๊อกแล้วกรุณารอ 15-20นาที",
        });
      }
    },
    CheckTab(expressCompanyId) {
      var tab = this.data.express.tabExpress;
      for (var i in this.data.express.tabExpress_List) {
        if (this.data.express.tabExpress_List[i]["id"] == expressCompanyId) {
          tab = i;
          break;
        }
      }
      return parseInt(tab);
    },
    CheckEmpty(v) {
      if (v == null) {
        return false;
      } else if (v.toString() == "") {
        return false;
      } else {
        return true;
      }
    },

    async OPPProvince(isUpdate, item) {
      this.loading = true;
      this.rulesDescription();

      if (this.province.length == 0) {
        let province = await axios.post(
          logisticService_dotnet + "Logistic/get-province",
          {},
          { headers: this.header_token }
        );
        if (province.status == 200) {
          var data = [];
          for (var i in province.data) {
            var dataItem = province.data[i];
            dataItem["disabled"] = false;
            data.push(dataItem);
          }
          this.province = data;
          this.provinceTemp = data;
        }
      } else {
        this.province = this.provinceTemp;
      }

      let provinceGroupItem = await axios.post(
        logisticService_dotnet + "Logistic/get-provinceGroupItem",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
          shopId: this.data.shop.id,
          expressCompanyId: item.id,
        },
        { headers: this.header_token }
      );
      if (provinceGroupItem.status == 200) {
        this.provinceGroupItem = provinceGroupItem.data;
      }

      this.opp.isUpdate = false;
      this.total = 0;
      this.selectProvince = [];
      this.provinceGroup.id = 0;
      this.provinceGroup.shopId = this.data.shop.id;
      this.provinceGroup.description = null;
      this.provinceGroup.expressCompanyId = item.id;

      for (var j in this.provinceGroupItem) {
        this.selectProvince.push(this.provinceGroupItem[j].provinceId);
      }

      for (var k in this.province) {
        if (this.selectProvince.includes(this.province[k].id)) {
          this.province[k].disabled = true;
        }
      }

      if (isUpdate) {
        this.opp.isUpdate = true;
        this.provinceGroup.id = item.provinceGroupId;
        this.provinceGroup.description = item.text;

        var selectProvince = [];
        for (var o in this.provinceGroupItem) {
          if (this.provinceGroupItem[o].provinceGroupId == item.provinceGroupId) {
            selectProvince.push(this.provinceGroupItem[o].provinceId);
          }
        }

        for (var l in this.province) {
          if (selectProvince.includes(this.province[l].id)) {
            this.province[l].disabled = false;
          }
        }

        this.total = selectProvince.length;
      }
      this.loading = false;
      this.opp.dialog = true;
    },
    async UpdateProvinceGroup() {
      await this.rulesDescriptionCheck();
      if (this.$refs.oppdialog.validate()) {
        if (this.provinceGroup.id == 0 && this.total == 0) {
          this.AlertWarning("เลือกจังหวัด");
          return;
        }

        var header = 0;
        for (var i in this.deliveryPrice) {
          if (
            this.provinceGroup.expressCompanyId == this.deliveryPrice.expressCompanyID
          ) {
            if (this.deliveryPrice[i].data.length != 0) {
              header = this.deliveryPrice[i].data[0].column.length;
            }
          }
        }

        if (this.total == 0 && header == 1) {
          this.AlertError();
          return;
        }

        this.loading = true;
        let response = await axios.post(
          logisticService_dotnet + "Logistic/update-provincegroup-branch",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
            shopId: this.data.shop.id,
            provinceGroup: this.provinceGroup,
            provinceIdList: this.provinceIdList,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          this.deliveryPrice = response.data.express;
          await this.SetDeliveryPrice();
          this.loading = false;
          this.opp.dialog = false;
          this.AlertSuccess();
        } else {
          this.loading = false;
          this.AlertError();
        }
      }
    },
    rulesDescription() {
      this.provinceGroup.rulesDescription = [];
    },
    rulesDescriptionCheck() {
      this.provinceGroup.rulesDescription = [(v) => !!v || "กรุณาระบุ"];
    },

    toBack(path) {
      this.$router.push("/" + path);
    },
    isNumberWithDot,
    isNumberWNoDot,
    formatMoney,
    Trim_value,
    Trim_rules,
    formatDatetime,
    formatDate_thai,
    formatDate_eng,
    everyDay,
    GetHourList,
    GetMinuteList,
    AlertSuccess,
    AlertWarning,
    AlertError,

    // วันที่
    CheckDateTime(startDate, endDate) {
      if (startDate != null && endDate != null) {
        var PstartDate = startDate;
        var PendDate = endDate;
        if (new Date(startDate) > new Date(endDate)) {
          PstartDate = endDate;
          PendDate = startDate;
        }
        startDate = PstartDate;
        endDate = PendDate;
      }
      this.startDate = startDate;
      this.endDate = endDate;
      this.dateStart = this.formatDate(startDate);
      this.dateEnd = this.formatDate(endDate);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
  },
};
</script>

<style scoped>
>>> .v-input__prepend-inner {
  margin-top: 10px;
}
</style>
